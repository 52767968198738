import React from "react";
import { Modal } from "antd";
import css from "./style.module.scss";

const ImageModal = ({ image, setIsImageShow, isImageShow }) => {
  return (
    <Modal
      centered
      closable={false}
      footer={false}
      visible={isImageShow}
      onOk={() => setIsImageShow(false)}
      onCancel={() => setIsImageShow(false)}
    >
      <div className={css.image}>
        <div
          className={css.closeIcon}
          onClick={() => {
            setIsImageShow(false);
          }}
        />
        <img src={image} alt="" />
      </div>
    </Modal>
  );
};

export default ImageModal;
