import menu from "./menuReducers";
import builds from "./buildsReducers";
import forge from "./forgeReducer";
import documents from "./documentsReducers";
import user from "./user";
import { combineReducers } from "redux";
const rootReducer = combineReducers({
  menu,
  builds,
  documents,
  user,
  forge
});
export default rootReducer;
