import React from "react";
import css from "./style.module.scss";

const DropDown = ({ data, sortBy, setSortBy }) => {
  return (
    <div className={css.content}>
      {data.map((el, i) => (
        <div
          key={i}
          className={css.item}
          onClick={() => {
            setSortBy(i);
          }}
        >
          {i === sortBy && (
            <img
              className={css.icon}
              src="/assets/icons/documents/Tick.svg"
              alt="tick"
            />
          )}
          <div className={css.text}> {el} </div>
        </div>
      ))}
    </div>
  );
};

export default DropDown;
