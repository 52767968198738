import React, { useEffect } from "react";
import propTypes from "prop-types";
import { Spin } from "antd";
import { connect } from "react-redux";
import Appbar from "./Appbar";
import { getBuildsRequest } from "../../redux/actions/buildsActions";
import Menu from "./Menu";
import css from "./style.module.scss";

const Layout = ({ children, useMenu, builds, getBuildsRequest }) => {
  useEffect(() => {
    getBuildsRequest();
  }, [getBuildsRequest]);

  return (
    <div className={css.container}>
      <Menu />
      <div className={css.content}>
        <Appbar useMenu={useMenu} />
        {!builds.isLoading && !builds.error ? (
          <div className={css.mainContent}>{children}</div>
        ) : (
          <div className={css.spin}>
            {builds.isLoading && <Spin size="large" />}
          </div>
        )}
      </div>
    </div>
  );
};
Layout.propTypes = {
  children: propTypes.oneOfType([
    propTypes.arrayOf(propTypes.node).isRequired,
    propTypes.node.isRequired,
  ]),
};

const mapStateToProps = (state) => {
  return {
    builds: state.builds,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBuildsRequest: () => dispatch(getBuildsRequest()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
