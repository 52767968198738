import { all } from "redux-saga/effects";

import buildSaga from "./buildSaga";
import documentsSaga from "./documentsSaga";
import forgeSaga from "redux/sagas/forgeSaga";
import userSaga from "redux/sagas/userSaga";
function* saga() {
  yield all([
      buildSaga(),
      documentsSaga(),
      forgeSaga(),
      userSaga()
  ]);
}

export default saga;
