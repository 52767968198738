/*global Autodesk*/
/*global THREE*/
import Toolkit from "components/ForgeViewer/Viewer.Toolkit";

export default class Markup3d {

    viewerEventHandlers = [
        {
            event: Autodesk.Viewing.CAMERA_CHANGE_EVENT,
            handler: this.update.bind(this),
        },
        {
            event: Autodesk.Viewing.ISOLATE_EVENT,
            handler: this.update.bind(this),
        },
        {
            event:  Autodesk.Viewing.HIDE_EVENT,
            handler: this.update.bind(this),
        },
        {
            event:  Autodesk.Viewing.SHOW_EVEN,
            handler: this.update.bind(this),
        },
    ]

    markerEventHandlers = [
        {
            event: 'click',
            handler: this.onClick.bind(this),
        },
        {
            event: 'mouseover',
            handler: this.onMouseOver.bind(this),
        },
        {
            event: 'mouseout',
            handler: this.onMouseOut.bind(this),
        },
    ]

    svg = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9983 1.87407C9.20285 1.87407 6.67268 3.0048 4.84048 4.84048C3.0048 6.67268 1.87407 9.20285 1.87407 11.9983C1.87407 14.7971 3.0048 17.3273 4.84048 19.1595C6.67267 20.9917 9.20285 22.1259 11.9983 22.1259C14.7971 22.1259 17.3273 20.9917 19.1595 19.1595C20.9917 17.3273 22.1259 14.7972 22.1259 11.9983C22.1259 9.20285 20.9917 6.67268 19.1595 4.84048C17.3273 3.0048 14.7972 1.87407 11.9983 1.87407ZM3.51432 3.51432C5.68504 1.34361 8.68635 5.68586e-07 11.9983 8.58123e-07C15.3137 1.14796e-06 18.315 1.34361 20.4857 3.51432C22.6564 5.68504 24 8.68635 24 11.9983C24 15.3137 22.6564 18.315 20.4857 20.4857C18.315 22.6564 15.3137 24 11.9983 24C8.68634 24 5.68503 22.6564 3.51432 20.4857C1.34361 18.315 -3.05531e-06 15.3137 -2.76547e-06 11.9983C-2.47594e-06 8.68634 1.34361 5.68504 3.51432 3.51432ZM12.103 5.73739C12.6195 5.73739 13.0417 6.15966 13.0417 6.67617C13.0417 7.19267 12.6195 7.61146 12.103 7.61146L11.8936 7.61146C11.3771 7.61146 10.9583 7.19267 10.9583 6.67617C10.9583 6.15966 11.3771 5.73739 11.8936 5.73739L12.103 5.73739ZM11.0699 9.77519C11.0699 9.25869 11.4922 8.8399 12.0087 8.8399C12.5252 8.8399 12.944 9.25869 12.944 9.77519L12.944 17.3238C12.944 17.8403 12.5252 18.2626 12.0087 18.2626C11.4922 18.2626 11.0699 17.8403 11.0699 17.3238L11.0699 9.77519Z" fill="white"/>
        </svg>`

    constructor (viewer, dbId, markerName) {
        this.id = Toolkit.guid()
        this.markerName = markerName
        this.viewer = viewer
        this.dbId = dbId
        this.visible = false

        this.el = document.createElement('div');
        this.el.id = this.id

        this.el.classList.add('marker-3d')

        this.offset = {
            x: viewer.container.offsetWidth,
            y: viewer.container.offsetHeight
        }

        this.markerEventHandlers.forEach((entry) => {
            this.el.addEventListener(entry.event, entry.handler)
        })

        this.el.insertAdjacentHTML('beforeend', this.svg);

        this.tooltip = this.createTooltip()

        this.el.appendChild(this.tooltip)

        viewer.container.appendChild(this.el)

        this.viewerEventHandlers.forEach((entry) => {
            viewer.addEventListener(entry.event, entry.handler)
        })

        this.update().then()
    }

    async update() {
        this.setVisible(true)
        const model = this.viewer.model
        const worldBoundingBox =  await Toolkit.getWorldBoundingBox(model,  this.dbId)

        const pos = this.viewer.worldToClient(
            worldBoundingBox.center()
        );

        this.el.style.left = Math.floor(pos.x - this.el.offsetWidth / 2 ) + "px"
        this.el.style.top = Math.floor(pos.y - this.el.offsetHeight / 2) + "px"

        if(this.checkOcclusion(pos)) {
            this.setVisible(false)
        } else {
            this.setVisible(true)
        }

    }

    normalize (screenPoint) {
        const viewport = this.viewer.navigation.getScreenViewport()

        return {
            x: (screenPoint.x - viewport.left) / viewport.width,
            y: (screenPoint.y - viewport.top) / viewport.height
        }

    }

    getHitData (x, y) {
        y = 1.0 - y

        x = x * 2.0 - 1.0
        y = y * 2.0 - 1.0

        const vpVec = new THREE.Vector3(x, y, 1)

        const result = this.viewer.impl.hitTestViewport(
            vpVec, false)

        return result || null
    }

    checkOcclusion (pos) {
        const n = this.normalize({
            x: pos.x + this.el.offsetWidth,
            y: pos.y + this.el.offsetHeight
        })

        const hitData = this.getHitData(n.x, n.y)

        if (hitData) {
            if (hitData.dbId !== this.dbId) {
                return true
            }

            const dist = {
                x: hitData.intersectPoint.x,
                y: hitData.intersectPoint.y,
                z: hitData.intersectPoint.z
            }

            const d =
                dist.x * dist.x +
                dist.y * dist.y +
                dist.z * dist.z

            if (d > 25) {
                return true
            }
        }

        return false
    }

    setVisible(show) {
        this.el.style.display = show ? 'block' : 'none'
    }

    async onClick() {
        this.viewer.clearSelection()
        const bounds = await Toolkit.getWorldBoundingBox(this.viewer.model, this.dbId)
        this.viewer.navigation.fitBounds(false, bounds.expandByScalar(10))
        this.viewer.select(this.dbId)
        const event = new CustomEvent('markerClickEvent', {detail: this})
        this.el.dispatchEvent(event)
    }

    onMouseOver() {
        this.tooltip.style.display = 'block'
    }

    onMouseOut() {
        this.tooltip.style.display = 'none'
    }

    createTooltip() {
        let tooltip = document.createElement('div')
        tooltip.classList.add('marker-tooltip')
        tooltip.innerText = 'Click to see detail info'

        return tooltip

    }


    get data() {
        return [
            {
            name: "Info",
            data: [
                {
                    type: "text",
                    name: "Name",
                    value: this.markerName,
                },
                {
                    type: "text",
                    name: "Location",
                    value: "Finish Face: Interior",
                },
                {
                    type: "photo",
                    name: "Photo",
                    value: "/assets/images/wall.png",
                },
            ],
        },
            {
                name: "Structural",
                data: [],
            },
            {
                name: "Dimentions",
                data: [],
            },
            {
                name: "Identity Data",
                data: [],
            },
            {
                name: "Phasing",
                data: [],
            },
            {
                name: "Energy Analysis",
                data: [],
            },
            {
                name: "Documents",
                data: [
                    {
                        type: "file",
                        name: "Materials & Components",
                        value: "someFile",
                    },
                    {
                        type: "file",
                        name: "Instruments",
                        value: "someFile",
                    },
                    {
                        type: "file",
                        name: "License",
                        value: "someFile",
                    },
                ],
            },
            {
                name: "Other",
                data: [],
            },
        ]
    }

    remove() {
       const el =  document.getElementById(this.id)
        this.viewerEventHandlers.forEach((entry) => {
            this.viewer.removeEventListener( entry.event, entry.handler)
        })
        this.markerEventHandlers.forEach((entry) => {
            this.el.removeEventListener(entry.event, entry.handler)
        })
        this.viewer.clearSelection()
        el.remove()
        this.viewer.fitToView()
    }
}
