import { all, put, takeLatest } from "redux-saga/effects";
import actions, { setStateActions } from '../actions/userActions'

function* LOGIN({ password }) {
  if (password === process.env.REACT_APP_ACCESS_KEY) {
    const now = (new Date()).getTime();
    yield put(
        setStateActions({
          expiry: now + 86400000,
        }),
    )
    return {result: true}
  } else {
    return {result: false}
  }
}

export function* LOGOUT() {
  yield put( setStateActions({
    id: "5ed759dad5fe4146b268b161",
    name: "Joonas",
    sName: "Nieminen",
    photo: "/public/assets/user/fb_profi_3.jpg",
    expiry: null,
  }))
}


export default function* userSaga() {
  yield all([
    takeLatest(actions.LOGIN, LOGIN),
    takeLatest(actions.LOGOUT, LOGOUT),
  ])
}

