import React, { useState, useEffect } from "react";
import { Popover } from "antd";
import classnames from "classnames";
import css from "./style.module.scss";
import Menu from "./Menu";
import QuickAccess from "./QuickAccess";
import AreaAnalysis from "./AreaAnalysis";
import PointInterest from "./PointInterest";
import Iot from "./Iot";
import Maintenance from "./Maintenance";
import Precast from "./Precast";
import useForgeViewer from "context/ForgeViewerContext";

const PopoverContent = ({ name }) => <div className={css.popover}>{name}</div>;

const buttonsNavigation = [
  {
    component: QuickAccess,
    name: "Quick access",
    id: "quick",
  },
  {
    component: AreaAnalysis,
    name: "Area analysis",
    id: "area",
  },
  {
    component: PointInterest,
    name: "Point of interest",
    id: "point",
  },
  {
    component: Iot,
    name: "Iot",
    id: "iot",
  },
  {
    component: Maintenance,
    name: "Maintenance",
    id: "maintenance",
  },
  {
    component: Precast,
    name: "Precast status",
    id: "precast",
  },
];
const Interface = () => {
  const [activeButton, setActiveButton] = useState(null);
  const { loading } = useForgeViewer();


  useEffect(() => {
    setActiveButton(null)
  }, [loading])

  return (
    loading ? null :

    <div className={css.interface}>
      <div className={css.buttonsLeft}>
        {buttonsNavigation.map((button, idx) => (
          <Popover
            key={idx}
            placement="right"
            content={<PopoverContent name={button.name} />}
          >
            <div
              onClick={() => {
                if (idx === activeButton) {
                  setActiveButton(null);
                } else {
                  setActiveButton(idx);
                }
              }}
              className={css.button}
            >
              <div
                className={classnames(
                  css.icon,
                  {
                    [classnames(css.active, { [css[button.id]]: true })]:
                      idx === activeButton,
                  },
                  {
                    [classnames(css.notActive, { [css[button.id]]: true })]:
                      idx !== activeButton,
                  }
                )}
              />
            </div>
          </Popover>
        ))}
      </div>
      {activeButton !== null && (
        <Menu
          Component={buttonsNavigation[activeButton].component}
          setActiveButton={setActiveButton}
          name={buttonsNavigation[activeButton].name}
        />
      )}
    </div>

  );

};

export default Interface;
