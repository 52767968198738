import Chart from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

const getPercantage = (currentVal, defaultVal) =>
  (currentVal * 100) / defaultVal;

const reloadCanvas = (parrentId, chartId) => {
  let barChart = document.getElementById(parrentId);
  var canv = document.getElementById(chartId);
  barChart.removeChild(canv);
  canv = document.createElement("canvas");
  canv.id = chartId;
  barChart.appendChild(canv);
};

const reducer = (accumulator, currentValue) => accumulator + currentValue;

export const BarChart = (data, onClick) => {
  reloadCanvas("barChart", "barCanvas");

  let newDataSet = [];
  let squareArr = [];

  data.forEach((el, i) => {
    squareArr.push(+el.pintaAla);
  });

  let allSquare = squareArr.reduce(reducer);

  data.forEach((el, i) => {
    newDataSet.push({
      label: el.tilatyyppi,
      backgroundColor: [el.color],
      data: [getPercantage(squareArr[i], allSquare).toFixed(1)],
    });
  });

  let ctx = document.getElementById("barCanvas").getContext("2d");

  const chBar = new Chart(ctx, {
    type: "bar",
    plugins: [ChartDataLabels],

    data: {
      labels: ["Pinta-ala"],
      datasets: newDataSet,
    },

    options: {
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            var label = data.datasets[tooltipItem.datasetIndex].label || "";

            if (label) {
              label += ": " + tooltipItem.yLabel + "%";
            }
            return label;
          },
        },
      },
      onClick: function(e, items){
        const activeDataSet = chBar.getDatasetAtEvent(e);
        if (items.length > 0 && onClick) {
          onClick(activeDataSet[0]._datasetIndex)
        }
      },
      plugins: {
        datalabels: {
          display: false,
        },
      },

      legend: {
        position: "bottom",
        align: "start",
        fullWidth: false,
        labels: {
          fontColor: "#ffffff",
          fontSize: 14,
          usePointStyle: true,
          boxWidth: 4,
        },
      },

      scales: {
        yAxes: [
          {
            gridLines: {
              color: "rgba(138,144,160, 0.3)",
              drawOnChartArea: false,
              drawTicks: false,
            },

            ticks: {
              fontColor: "#ffffff",
              beginAtZero: true,
              padding: 6,
              callback: function (value, index, values) {
                return value + "%";
              },
            },
          },
        ],

        xAxes: [
          {
            gridLines: {
              display: false,
            },

            ticks: {
              display: false,
            },

            // barPercentage: 0.7,
            // categoryPercentage: 1,
          },
        ],
      },
    },
  });
};

export const PieChart = (data, onClick) => {
  reloadCanvas("pieChart", "pieCanvas");

  let squareArr = [];
  let squareArrPer = [];
  let nameArr = [];
  let colorArr = [];

  data.forEach((el, i) => {
    squareArr.push(+el.pintaAla);
    nameArr.push(el.tilatyyppi);
    colorArr.push(el.color);
  });

  let allSquare = squareArr.reduce(reducer);

  data.forEach((el, i) => {
    squareArrPer.push([getPercantage(squareArr[i], allSquare).toFixed(1)]);
  });

  let ctx = document.getElementById("pieCanvas").getContext("2d");


  new Chart(ctx, {
    type: "doughnut",
    plugins: [ChartDataLabels],

    data: {
      labels: nameArr,

      datasets: [
        {
          data: squareArrPer,
          backgroundColor: colorArr,
          borderWidth: 0,
          weight: 20,
          borderColor: colorArr,
          barPercentage: 0.7,
          categoryPercentage: 1,
        },
      ],
    },

    options: {
      cutoutPercentage: 60,

      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            var label = data.labels[tooltipItem.index] || "";
            if (label) {
              label += ": " + data.datasets[0].data[tooltipItem.index][0] + "%";
            }
            return label;
          },
        },
      },
      onClick:function(e, items){
        if (items.length > 0 && onClick) {
          onClick(items[0]._index)
        }
      },
      plugins: {
        datalabels: {
          formatter: (value) => value + "%",
          color: "#FFFFFF",
        },
      },

      legend: {
        position: "right",
        align: "center",
        fullWidth: false,

        labels: {
          fontColor: "#ffffff",
          fontSize: 14,
          usePointStyle: true,
          boxWidth: 4,
        },
      },
      layout: {
        padding: {
          left: 0,
          right: 150,
          top: 0,
          bottom: 0,
        },
      },

      scales: {
        yAxes: [
          {
            display: false,
          },
        ],

        xAxes: [
          {
            gridLines: {
              display: false,
            },

            ticks: {
              display: false,
            },

            // barPercentage: 0.7,
            // categoryPercentage: 1,
          },
        ],
      },
    },
  });
};
