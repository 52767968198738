import React, {useEffect} from "react";
import { connect } from "react-redux";
import { Spin } from "antd";
import Appbar from "../../components/Layout/Appbar";
import ForgeViewer from "components/ForgeViewer";
import Interface from "./Interface";
import useForgeViewer from "context/ForgeViewerContext";

import css from "./style.module.scss";

const BuildModel = ({builds}) => {

  const { setViewer, setLoading } = useForgeViewer()
  const model = builds.builds[builds.currentBuildId]

  const setViewerHandler = (viewer) => {
    setViewer(viewer)
    setLoading(false)
  }

  useEffect(() => {
    setLoading(true)
  }, [model])

  return (
      !builds.builds ? <Spin /> :
      <div className={css.model}>
        <div className={css.appbar}>
          <Appbar />
        </div>
        <div className={css.interface}>
          <Interface/>
        </div>
        {
          !model ? null : <ForgeViewer
              onViewerCreated={setViewerHandler}
              model={model}
          />
        }
      </div>
  )
}

const mapStateToProps = (state) => {
  return {
    builds: state.builds,
  };
};

export default connect(mapStateToProps, null)(BuildModel);
