import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Row, Col } from "antd";
import Navbar from "./NavBar";
import DocumentCard from "./DocumentCard";
import UploadModal from "./UploadModal";
import css from "./style.module.scss";
const buttonsId = ["materials", "reports", "service", "plan", "rescue"];
const Documents = ({
  sort,
  search,
  setDocumentRequest,
  getDocumentRequest,
}) => {
  const [documents, setDocuments] = useState(0);
  const [sortBy, setSortBy] = useState(0);
  const [listView, setContentView] = useState(false);
  const [searchFileName, setSearchFileName] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const buildId = useSelector((state) => state.builds.currentBuildId);
  const data = useSelector((state) => state.documents);
  useEffect(() => {
    getDocumentRequest(buttonsId[documents], buildId);
  }, [buildId, documents, getDocumentRequest]);
  useEffect(() => {
    sort(sortBy);
  }, [sortBy, sort]);

  return (
    <div className={css.content}>
      <div className={css.title}>
        <div className={css.text}>Documents</div>
        <div
          className="button purple-inline"
          onClick={() => {
            setModalVisible(true);
          }}
        >
          + Add a file
        </div>
        <UploadModal
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
          setDocumentRequest={setDocumentRequest}
          buildId={buildId}
          documentType={buttonsId[documents]}
        />
      </div>
      <Navbar
        documents={documents}
        setDocuments={setDocuments}
        sortBy={sortBy}
        setSortBy={setSortBy}
        contentView={listView}
        setContentView={setContentView}
        searchFileName={searchFileName}
        setSearchFileName={setSearchFileName}
        search={search}
        getDocumentRequest={getDocumentRequest}
        buildId={buildId}
        documentType={buttonsId[documents]}
      />
      <Row gutter={[listView ? 8 : 24, listView ? 8 : 24]} className={css.row}>
        {data[buttonsId[documents]] && (
          <>
            {data[buttonsId[documents]].map((el, i) => {
              return (
                <Col span={listView ? 24 : 6} key={i}>
                  <DocumentCard
                    data={el}
                    documentType={buttonsId[documents]}
                    key={i}
                    listView={listView}
                  />
                </Col>
              );
            })}
          </>
        )}
      </Row>
    </div>
  );
};

export default Documents;
