import axios from "axios";
import { BASE_API_URL } from "../../configs/base";

class BuildApi {
  getBuilds() {
    return axios
      .get(`${BASE_API_URL}/api/build/all`)
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        console.log(e);
      });
  }
  setNotification(action) {
    let formData = new FormData();
    formData.append(
      "fileData",
      JSON.stringify({
        buildId: action.buildId,
        notificationId: action.index,
        userId: action.user.id,
      })
    );
    return axios
      .put(`${BASE_API_URL}/api/notifiaction/build`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  setPrecast({id, ...rest}) {
    return axios
        .post(`${BASE_API_URL}/api/build/precasts/${id}`, rest )
        .then(({data}) => {
          return data;
        })

  }

  markAllRead(action) {
    let formData = new FormData();
    formData.append(
      "fileData",
      JSON.stringify({
        buildId: action.buildId,
        userId: action.user.id,
      })
    );
    return axios
      .put(`${BASE_API_URL}/api/notifiaction/all`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        console.log(e);
      });
  }
}
export default new BuildApi();
