import { HIDDEN_MENU } from "../actions/menuActions";
const initialState = {
  isOpen: !(window.innerWidth <= 576),
};
const menu = (state = initialState, action) => {
  switch (action.type) {
    case HIDDEN_MENU:
      return {
        isOpen: !state.isOpen,
      };

    default:
      return state;
  }
};
export default menu;
