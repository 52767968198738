import store from 'store'
import actions from '../actions/userActions'

const INITIAL_STATE = (storedItems) => {
  const items = {}
  const storedUser = store.get(`app.user`)
  Object.keys(storedItems).forEach((key) => {
    items[key] = storedUser?.hasOwnProperty(key) ? storedUser[key] : storedItems[key]
  })

  const now = (new Date()).getTime()
  if (now > items.expiry) {
    store.remove(`app.user`)
  }
  return items
}

const initialState = INITIAL_STATE({
    id: "5ed759dad5fe4146b268b161",
    name: "Joonas",
    sName: "Nieminen",
    photo: "/public/assets/user/fb_profi_3.jpg",
    expiry: null,
  })

const setState = (state, payload) => {
  let storeUser = store.get(`app.user`)
  store.set(`app.user`, { ...storeUser, ...payload })
  return { ...state, ...payload }
}

const user = (state = initialState, {type, payload}) => {
  switch (type) {
    case actions.SET_STATE:
      return setState(state, payload)
    default:
      return state;
  }
};
export default user;
