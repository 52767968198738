const interactiveModel = {
  header: "Interactive model",
  contact: "Contact Info",
  address: "Address:",
  phones: "Phones:",
  social: "Social networks:",
  property: "Kiinteistö",
  name: "Nimi:",
  street: "Katuosoite:",
  postalCode: "Postinumero ja postitoimipaikka:",
  owner: "Omistaja:",
  location: "Sijainti",
  municipality: "Kunta:",
  block: "Kortteli:",
  district: "Kaupunginosa/kylä:",
  plot: "Tontin/tilan nro:",
  space: "Tilavuus, Pinta-Alat, Tilat",
  companyType: "Yhtiömuoto:",
  graduationYear: "Valmistumisvuosi:",
  totalVolume: "Kokonaistilavuus (m³):",
  apartmentsCount: "Asuinhuoneistot (kpl):",
  technicalCount: "Tekniset tilat (kpl):",
  otherObjects: "Muut tilat (kpl):",
  totalArea: "Kokonaispinta-ala (m²):",
  totalApartmentArea: "Huoneistoala yhteensä (m²):",
};
export default interactiveModel;
