import React from "react";
import css from "./style.module.scss";
import { useHistory } from "react-router-dom";

const NotFound = () => {
  const history = useHistory();
  const location = history.location;

  return (
      <section className={css.notFound}>
        <div className={css.errorNum}>404</div>
        <div className={css.text}>OOPS! NOTHING WAS FOUND</div>
        <a
            href="/"
            className={css.link}
            onClick={(e) => {
              e.preventDefault();
              if (!history.goBack()) {
                setTimeout(() => {
                  if (history.location === location) {
                    history.push("/");
                  }
                }, 500);
              }
            }}
        >
          Go back
        </a>
      </section>
  );
};

export default NotFound;
