import React, { useState } from "react";
import { Popover, Row, Col } from "antd";
import { connect } from "react-redux";
import classNames from "classnames";
import printJS from "print-js";
import css from "./style.module.scss";
import Dropdown from "./Dropdown";
import {
  downloadDocumentRequest,
  renameDocumentRequest,
  deleteDocumentRequest,
} from "../../../redux/actions/documentsActions";
import { useEffect } from "react";
const RenameInput = ({ name, onRename, setRenameInput }) => {
  return (
    <input
      defaultValue={name}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          if (e.target.value !== name) {
            onRename(e.target.value);
          }
          setRenameInput(false);
        }
      }}
    />
  );
};

const ListItem = ({ image, name, description, date, popover }) => (
  <div className={css.listItem}>
    <Row align="middle">
      <Col span={1}>
        <img src={image} className={css.image} alt="extension" />
      </Col>
      <Col span={4}>{name}</Col>
      <Col span={16}>{description}</Col>
      <Col span={2}>{date.split(" ")[0]}</Col>
      <Col span={1}>{popover}</Col>
    </Row>
  </div>
);
const GridItem = ({
  popover,
  name,
  image,
  description,
  date,
  RenameInput,
  showRenameInput,
}) => (
  <div className={css.content}>
    <div className={css.image}>
      <div className={css.dropdown}>{popover}</div>
      <div className={css.extension}>
        <img src={image} alt="extension" />
      </div>
    </div>
    <div className={css.text}>
      <div className={css.mainTex}>
        {showRenameInput ? RenameInput : <div className={css.name}>{name}</div>}
        <div className={css.description}>{description}</div>{" "}
      </div>

      <div className={css.date}> {date.split(" ")[0]}</div>
    </div>
  </div>
);

const PopOverDots = ({
  setDropDownVisible,
  dropDownVisible,
  setRenameInput,
  onDownload,
  onPrint,
  onDelete,
}) => (
  <Popover
    placement="bottomRight"
    content={
      <Dropdown
        setRenameInput={setRenameInput}
        onDownload={onDownload}
        onPrint={onPrint}
        onDelete={onDelete}
      />
    }
    trigger="click"
    onVisibleChange={(isVisible) => {
      setDropDownVisible(isVisible);
    }}
  >
    <div
      className={classNames(css.dots, {
        [css.active]: dropDownVisible,
      })}
    />
  </Popover>
);

const DocumentCard = ({
  listView,
  data,
  downloadDocumentRequest,
  renameDocumentRequest,
  deleteDocumentRequest,
  buildId,
  downloadFile,
}) => {
  const [dropDownVisible, setDropDownVisible] = useState(false);
  const [showRenameInput, setRenameInput] = useState(false);
  const [downloadAction, setDownloadAction] = useState(false);
  const [printAction, setPrintAction] = useState(false);
  let img = "";
  if (data)
    switch (data.extension) {
      case "pdf":
        img = "/assets/icons/documents/Pdf.svg";
        break;
      case "doc" || "docx":
        img = "/assets/icons/documents/Doc.svg";
        break;
      case "xlsx":
        img = "/assets/icons/documents/Excel.svg";
        break;
      default:
        break;
    }

  const getCurrentFile = () => {
    return {
      name: data.name,
      type: data.documentType,
      extension: data.extension,
    };
  };
  const onDelete = () => {
    deleteDocumentRequest(buildId, getCurrentFile());
  };
  const onRename = (newName) => {
    renameDocumentRequest(buildId, getCurrentFile(), newName);
  };
  const onPrint = () => {
    setPrintAction(true);
    downloadDocumentRequest(buildId, getCurrentFile());
  };
  const onDownload = () => {
    setDownloadAction(true);
    downloadDocumentRequest(buildId, getCurrentFile());
  };

  const propsItem = {
    showRenameInput: showRenameInput,
    RenameInput: (
      <RenameInput
        setRenameInput={setRenameInput}
        name={data.name}
        onRename={onRename}
      />
    ),
    image: img,
    name: data.name,
    description: data.description,
    date: data.date,
    popover: (
      <PopOverDots
        onPrint={onPrint}
        onDownload={onDownload}
        onDelete={onDelete}
        setRenameInput={setRenameInput}
        dropDownVisible={dropDownVisible}
        setDropDownVisible={setDropDownVisible}
      />
    ),
  };
  useEffect(() => {
    if (downloadFile && printAction) {
      printJS(downloadFile.link);
      setPrintAction(false);
    }
  }, [downloadFile, printAction]);

  useEffect(() => {
    if (downloadFile && downloadAction) {
      const { name, link } = downloadFile;
      const tempLink = document.createElement("a");
      tempLink.href = link;
      tempLink.setAttribute("download", name);
      tempLink.click();
      setDownloadAction(false);
    }
  }, [downloadFile, downloadAction]);

  return (
    <>{listView ? <ListItem {...propsItem} /> : <GridItem {...propsItem} />}</>
  );
};

const mapStateToProps = (state) => {
  return {
    buildId: state.builds.currentBuildId,
    downloadFile: state.documents.file,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    downloadDocumentRequest: (buildId, file) =>
      dispatch(downloadDocumentRequest(buildId, file)),
    renameDocumentRequest: (buildId, file, newName) =>
      dispatch(renameDocumentRequest(buildId, file, newName)),
    deleteDocumentRequest: (buildId, file) =>
      dispatch(deleteDocumentRequest(buildId, file)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DocumentCard);
