import React from "react";
import { Row, Col, Spin } from "antd";
import { Link } from "react-router-dom";
import classNames from "classnames";
import css from "./style.module.scss";
import Card from "./Card";
import interactiveModelData from "../../data/text/interactiveModel";
import { BASE_API_URL } from "../../configs/base";

const InteractiveModel = ({ data }) => {
  const { builds, currentBuildId, isLoading } = data;
  const currentBuild = builds[currentBuildId];
  return (
    <>
      <div className={css.title}>Interactive model</div>
      <Row gutter={[24, 16]}>
        <Col xxl={18} xl={24} xs={24}>
          <Card>
            <div className={css.view}>
              <div className={css.header}>
                <div className={css.text}>3D model</div>
                <Link to="/model">
                  <div className="button purple">View 3D model</div>
                </Link>
              </div>
              {!isLoading ? (
                <img
                  src={`${BASE_API_URL}/public${currentBuild.iconBig}`}
                  className={css.image}
                  alt="icon"
                />
              ) : (
                <Spin />
              )}
            </div>
          </Card>
        </Col>
        <Col xxl={6} xl={8} xs={24} sm={12} md={12}>
          <Card title={interactiveModelData["contact"]}>
            <div className={css.propertyCard}>
              <div className={css.propertyCardTitle}>
                {interactiveModelData["address"]}
              </div>
              <div className={css.propertyCardText}>
                {currentBuild["contact"]["address"]}
              </div>
            </div>
            <div className={css.propertyCard}>
              <div className={css.propertyCardTitle}>
                {interactiveModelData["phones"]}
              </div>
              {currentBuild["contact"]["phones"].map((el, i) => (
                <div className={css.propertyCardText} key={`cp${i}`}>
                  {el}
                </div>
              ))}
            </div>
            <div className={css.propertyCard}>
              <div className={css.propertyCardTitle}>
                {interactiveModelData["social"]}
              </div>
              {currentBuild["contact"]["social"].map((el, i) => (
                <Link key={`cp${i}`} to={el.link}>
                  <div className={css.propertyCardSocial}>
                    <img src={el.icon} className={css.icon} alt="icon" />
                    <div className={classNames(css.link)}>{el.name}</div>
                  </div>
                </Link>
              ))}
            </div>
          </Card>
        </Col>
        <Col xxl={6} xl={8} xs={24} sm={12} md={12}>
          <Card title={interactiveModelData["property"]}>
            {Object.keys(currentBuild["property"]).map((el, i) => (
              <div className={css.propertyCard} key={`p${i}`}>
                <div className={css.propertyCardTitle}>
                  {interactiveModelData[el]}
                </div>
                <div className={css.propertyCardText}>
                  {currentBuild["property"][el]}
                </div>
              </div>
            ))}
          </Card>
        </Col>
        <Col xxl={6} xl={8} xs={24} sm={24} md={12}>
          <Card title={interactiveModelData["location"]}>
            {Object.keys(currentBuild["location"]).map((el, i) => (
              <div className={css.propertyCard} key={`p${i}`}>
                <div className={css.propertyCardTitle}>
                  {interactiveModelData[el]}
                </div>
                <div className={css.propertyCardText}>
                  {currentBuild["location"][el]}
                </div>
              </div>
            ))}
          </Card>
        </Col>
        <Col xxl={12} xl={24} xs={24}>
          <Card title={`Tilavuus, Pinta-Alat, Tilat`}>
            {currentBuild["space"].map((row, i) => {
              return (
                <Row gutter={[0, 0]} key={`${i}`}>
                  {Object.keys(row).map((col, j) => {
                    return (
                      <Col
                        md={8}
                        xs={24}
                        key={`${i}${j}`}
                        className={css.propertyCard}
                      >
                        <div className={css.propertyCardTitle}>
                          {interactiveModelData[col]}
                        </div>
                        <div className={css.propertyCardText}>{row[col]}</div>
                      </Col>
                    );
                  })}
                </Row>
              );
            })}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default InteractiveModel;
