import Chart from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

const LineChart = ({data, labels, color}, ctx) => {

  return new Chart(ctx, {
    type: "line",
    plugins: [ChartDataLabels],
    data:  {
      labels: labels,
      datasets: [{
        lineTension: 0,
        pointHoverBackgroundColor: `rgba(${color.join(',')}, 1)`,
        pointHoverBorderColor: `rgba(${color.join(',')}, 1)`,
        pointBorderColor: `rgba(${color.join(',')}, 0)`,
        pointBackgroundColor: `rgba(${color.join(',')}, 0)`,
        borderColor: `rgba(${color.join(',')}, 1)`,
        data: data,
      }]
    },
    options: {
      steppedLine: 'middle',
      plugins: {
        datalabels: {
          display: false,
        },
      },
      legend: {
        display: false,
      },
      scales: {
        yAxes: [
          {
            gridLines: {
              color: "rgba(138,144,160, 0.3)",
              drawOnChartArea: false,
              drawTicks: false,
            },
            ticks: {
              fontColor: "#ffffff",
              beginAtZero: true,
              padding: 6,
            },
          },
        ],

        xAxes: [
          {
            gridLines: {
              color: "rgba(138,144,160, 0.3)",
              drawOnChartArea: false,
              drawTicks: false,
              offsetGridLines: true
            },
            ticks: {
              fontColor: "#ffffff",
              beginAtZero: true,
              padding: 6,
            },
          },
        ],
      },
    }
  });

};

export default LineChart;
