import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getBuildsRequest } from "../redux/actions/buildsActions";
import BuildModel from "../components/BuildModel";
import { ForgeViewerProvider } from "context/ForgeViewerContext"


const BuildModelPage = ({ getBuildsRequest }) => {

  useEffect(() => {
    getBuildsRequest();
  }, [getBuildsRequest]);

  return <ForgeViewerProvider>
          <BuildModel />
        </ForgeViewerProvider>
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBuildsRequest: () => dispatch(getBuildsRequest()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BuildModelPage);
