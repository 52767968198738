import actions from '../actions/forgeActions'


const LOAD_DATA = (stored) => {
  const data = {}
  const sessionStorageData = JSON.parse(sessionStorage.getItem('forge_token'))

  if(!sessionStorageData)  return stored

  Object.keys(sessionStorageData).forEach(key => {
    data[key] =  sessionStorageData[key]
  })

  return data
}

const initialState = {
  loading: false,
  token: LOAD_DATA(null),
}


export default function forge(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
