import React from "react";
import classNames from 'classnames'
import css from "./style.module.scss";

export const TableAnalysis = ({ data, onClick, selected}) => {
  return (
    <div>
      <table className={css.table}>
        <thead>
          <tr>
            <th>Tilatyyppi</th>
            <th>Pinta-ala</th>
          </tr>
        </thead>

        <tbody>
          {data.map((el, i) => (
            <tr
                key={i}
                onClick={() => onClick(i)}
                className={classNames({ [css['active']]: selected === i})}
            >
              <td width="70%">{el.tilatyyppi}</td>
              <td width="30%" align="right">
                {el.pintaAla.toFixed(2)} m²
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export const TableMaintenance = ({ data }) => {
  return (
    <div>
      <table className={css.table}>
        <thead>
          <tr>
            <th>Code</th>
            <th>Task</th>
            <th>Result</th>
            <th>KRT/a</th>
            <th>2D</th>
          </tr>
        </thead>

        <tbody>
          {data.map((el, i) => (
            <tr key={i}>
              <td width="63">{el.code}</td>
              <td width="207" align="right">
                {el.task}
              </td>
              <td width="120" align="center">
                {el.result} {el.date}
              </td>
              <td width="71" align="right">
                {el.krt} m²
              </td>
              <td width="47" align="right">
                {el.download2d}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
