import {
  SET_DOCUMENT_REQUEST,
  SET_DOCUMENT_SUCCESS,
  SET_DOCUMENT_ERROR,
  GET_DOCUMENT_REQUEST,
  GET_DOCUMENT_SUCCESS,
  GET_DOCUMENT_ERROR,
  RENAME_DOCUMENT_REQUEST,
  RENAME_DOCUMENT_ERROR,
  DOWNLOAD_DOCUMENT_REQUEST,
  DOWNLOAD_DOCUMENT_SUCCESS,
  DOWNLOAD_DOCUMENT_ERROR,
  DELETE_DOCUMENT_REQUEST,
  DELETE_DOCUMENT_ERROR,
  SORT,
  SEARCH,
} from "../actions/documentsActions";
import sort from "../../utils/sorts";
import search from "../../utils/search";

const initialState = {
  materials: [],
  reports: [],
  service: [],
  plan: [],
  rescue: [],
  file: null,
  isLoading: false,
  error: null,
};
const documents = (state = initialState, action) => {
  switch (action.type) {
    case SET_DOCUMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case SET_DOCUMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        [action.documentType]: action.files,
      };
    case SET_DOCUMENT_ERROR:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };

    case GET_DOCUMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_DOCUMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        [action.documentType]: action.files,
      };
    case GET_DOCUMENT_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case RENAME_DOCUMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case RENAME_DOCUMENT_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case DOWNLOAD_DOCUMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case DOWNLOAD_DOCUMENT_SUCCESS:
      return {
        ...state,
        file: action.downloadFile,
        isLoading: false,
      };
    case DOWNLOAD_DOCUMENT_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case DELETE_DOCUMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case DELETE_DOCUMENT_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case SORT:
      return {
        ...state,
        materials: sort(state.materials, action.sortType),
      };
    case SEARCH:
      return {
        ...state,
        materials: search(action.fileName, initialState),
      };
    default:
      return state;
  }
};
export default documents;
