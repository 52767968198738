import React from "react";
import classNames from "classnames";
import css from "./style.module.scss";
const buttons = ["Download", "Print", "Rename", "Delete"];
const iconsKey = ["download", "print", "rename", "delete"];
const Dropdown = ({ setRenameInput, onDownload, onPrint, onDelete }) => {
  return (
    <div className={css.content}>
      {buttons.map((el, i) => (
        <div
          className={css.item}
          key={i}
          onClick={() => {
            if (el === "Rename") {
              setRenameInput((prev) => !prev);
            }
            if (el === "Download") {
              onDownload();
            }
            if (el === "Print") {
              onPrint();
            }
            if (el === "Delete") {
              onDelete();
            }
          }}
        >
          <div className={classNames(css.icon, { [css[iconsKey[i]]]: true })} />
          <div>{el}</div>
        </div>
      ))}
    </div>
  );
};
export default Dropdown;
