import {useCallback, useEffect, useState} from 'react'


export default function useLevels(viewer) {
  const [levels, setLevels] = useState([])
  const [floorSelector, setFloorSelector] = useState(null)
  const [activeLevel, setActiveLevel] = useState(null)
  const [loading, setLoading] = useState(true)

  const initFloors = useCallback(() => {
    const fSelector = viewer.getExtension("Autodesk.AEC.LevelsExtension")?.floorSelector
    setFloorSelector(fSelector);
    setActiveLevel(fSelector?.currentFloor || undefined)
    const levels = fSelector?.floorData || []

    setLevels(levels)
    setLoading(false)
  }, [viewer])


  const handlerSelectLevel = (i) => {

    if(activeLevel === i) {
      floorSelector.selectFloor(undefined, true)
      setActiveLevel(null)
    } else {
      floorSelector.selectFloor(i, true)
      setActiveLevel(i)
    }

  }

  useEffect(() => {
    initFloors();
  }, [initFloors])

  useEffect(() => {
    return () => {
      if(floorSelector) {
        floorSelector.selectFloor(undefined, true)
      }
    }
  }, [floorSelector])


  return {
    levels,
    activeLevel,
    levelsLoading: loading,
    selectLevel: handlerSelectLevel
  }
}
