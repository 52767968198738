import {
  GET_BUILDS_REQUEST,
  GET_BUILDS_SUCCESS,
  GET_BUILDS_ERROR,
  SET_CURRENT_BUILDS,
  SET_NOTIFICATION_REQUEST,
  SET_NOTIFICATION_ERROR,
  SET_PRECAST_STATE
} from "../actions/buildsActions";

const initialState = {
  error: false,
  currentBuildId: 0,
  isLoading: true,
  builds: [],
};

const setPrecastState = (state, {id, ...rest}) => {
  const currentBuilding = state.builds[state.currentBuildId]
  currentBuilding.precasts = rest.precasts
  return {...state }
}

const builds = (state = initialState, action) => {
  switch (action.type) {
    case GET_BUILDS_REQUEST:
      return {
        ...state,
        error: false,
        isLoading: true,
      };
    case GET_BUILDS_SUCCESS:
      return {
        ...state,
        builds: action.data,
        error: false,
        isLoading: false,
      };
    case GET_BUILDS_ERROR:
      return {
        ...state,
        error: action.error.error,
        isLoading: false,
      };
    case SET_CURRENT_BUILDS:
      return {
        ...state,
        currentBuildId: action.index,
      };

    case SET_NOTIFICATION_REQUEST:
      return {
        ...state,
      };

    case SET_NOTIFICATION_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case SET_PRECAST_STATE:
      return setPrecastState(state, action.data);
    default:
      return state;
  }
};
export default builds;
