import React from "react";
import { connect } from "react-redux";
import InteractiveModel from "../components/InteractiveModel";

const InteractivePage = ({ builds }) => {
  return <InteractiveModel data={builds} />;
};

const mapStateToProps = (state) => {
  return {
    builds: state.builds,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(InteractivePage);
