import React, { useState } from "react";
import classNames from "classnames";
import { Popover, Input } from "antd";
import css from "./style.module.scss";
import DropDown from "./DropDown";
const buttons = [
  "Materials & Component lists",
  "Reports",
  "Service contract management",
  "2D plan",
  "Rescue plan",
];
const dropDown = ["First added", "Last added", "A to Z", "Z to A", "Type"];
const Navbar = ({
  documents,
  setDocuments,
  setSortBy,
  sortBy,
  contentView,
  setContentView,
  searchFileName,
  setSearchFileName,
  getDocumentRequest,
  search,
  buildId,
  documentType,
}) => {
  const [dropDownVisible, setDropDownVisible] = useState(false);

  return (
    <div className={css.content}>
      <div className={css.buttons}>
        {buttons.map((buttonText, i) => (
          <div key={i} className={css.buttonNav}>
            <div
              className={classNames(css.buttonTab, {
                [css.active]: documents === i,
                [css.hover]: documents !== i,
              })}
              onClick={() => {
                setDocuments(i);
              }}
            >
              <div className={classNames(css.text)}>{buttonText}</div>
            </div>
            {i !== buttons.length - 1 && (
              <div
                className={classNames(
                  css.divider,
                  { [css.show]: true }

                  // { [css.hide]: i + 1 === documents || i === documents },
                  // { [css.show]: i + 1 !== documents || i !== documents }
                )}
              ></div>
            )}
          </div>
        ))}
      </div>
      <div className={css.filters}>
        <div className={css.sort}>
          <div className={css.text}>Sort by:</div>
          <Popover
            placement="bottomLeft"
            content={
              <DropDown data={dropDown} setSortBy={setSortBy} sortBy={sortBy} />
            }
            trigger="click"
            onVisibleChange={(isVisible) => {
              setDropDownVisible(isVisible);
            }}
          >
            <div className={css.dropDown}>
              <div className={css.text}>{dropDown[sortBy]}</div>
              <img
                className={classNames(
                  css.arrow,
                  {
                    [css.top]: dropDownVisible,
                  },
                  {
                    [css.bottom]: !dropDownVisible,
                  }
                )}
                src="/assets/icons/arrows/ArrowRightSmall.svg"
                alt="icon"
              />
            </div>
          </Popover>
        </div>
        <div className={css.search}>
          <Input
            defaultValue={searchFileName}
            onChange={(e) => {
              setSearchFileName(e.target.value);
            }}
            onKeyDown={(e) => {
              e.key === "Enter" && search(searchFileName);
            }}
            className={css.searchInput}
            size="default size"
            placeholder="Search a file"
            prefix={<img src="/assets/icons/documents/Search.svg" alt="icon" />}
          />
          <div className={css.contenViewType}>
            <div
              onClick={() => {
                setContentView(false);
              }}
              className={classNames(
                css.icon,
                { [css.grid]: !contentView },
                { [css.gridGrey]: contentView }
              )}
            />
            <div
              onClick={() => {
                setContentView(true);
              }}
              className={classNames(
                css.icon,
                { [css.list]: contentView },
                { [css.listGrey]: !contentView }
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
