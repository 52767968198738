import { put, takeLatest, call } from "redux-saga/effects";
import {
  setDocumentSuccess,
  setDocumentError,
  getDocumentSuccess,
  getDocumentError,
  renameDocumentError,
  downloadDocumentSuccess,
  downloadDocumentError,
} from "../actions/documentsActions";
import api from "../api/documentsApi";
import { BASE_API_URL } from "../../configs/base";
import getError from "../../utils/getError";

function* setDocument(action) {
  try {
    const list = yield call(api.setDocumentApi, action);
    if (list) yield put(setDocumentSuccess(list, action.documentType));
  } catch (error) {
    yield put(setDocumentError(getError(error).err));
  }
}

function* getDocuments(action) {
  try {
    const list = yield call(api.getDocumentApi, action);
    yield put(getDocumentSuccess(list, action.documentType));
  } catch (error) {
    yield put(getDocumentError(error));
  }
}

function* renameDocuments(action) {
  try {
    yield call(api.renameDocumentApi, action);
    const list = yield call(api.getDocumentApi, {
      buildId: action.buildId,
      documentType: action.file.type,
    });
    yield put(getDocumentSuccess(list, action.file.type));
  } catch (error) {
    yield put(renameDocumentError(error));
  }
}

function* downloadDocuments(action) {
  try {
    let downloadFile = yield call(api.downloadDocumentApi, action);
    downloadFile.link = BASE_API_URL + downloadFile.link;

    yield put(downloadDocumentSuccess(downloadFile));
    const list = yield call(api.getDocumentApi, {
      buildId: action.buildId,
      documentType: action.file.type,
    });
    yield put(getDocumentSuccess(list, action.file.type));
  } catch (error) {
    yield put(downloadDocumentError(error));
  }
}

function* deleteDocuments(action) {
  try {
    yield call(api.deleteDocumentApi, action);
    const list = yield call(api.getDocumentApi, {
      buildId: action.buildId,
      documentType: action.file.type,
    });
    yield put(getDocumentSuccess(list, action.file.type));
  } catch (error) {
    yield put(downloadDocumentError(error));
  }
}

function* documentsSaga() {
  yield takeLatest("SET_DOCUMENT_REQUEST", setDocument);
  yield takeLatest("GET_DOCUMENT_REQUEST", getDocuments);
  yield takeLatest("RENAME_DOCUMENT_REQUEST", renameDocuments);
  yield takeLatest("DOWNLOAD_DOCUMENT_REQUEST", downloadDocuments);
  yield takeLatest("DELETE_DOCUMENT_REQUEST", deleteDocuments);
}

export default documentsSaga;
