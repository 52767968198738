import React from "react";
import ReactDOM from "react-dom";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import createSagaMiddleware from "redux-saga";
import reducer from "./redux/reducers";
import saga from "./redux/sagas";
import "./index.scss";
import App from "./pages/App";
import * as serviceWorker from "./serviceWorker";
import "antd/dist/antd.css";
const sageMiddleware = createSagaMiddleware();
const store = createStore(reducer, applyMiddleware(sageMiddleware));

sageMiddleware.run(saga);
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
