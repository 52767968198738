import React, { useState } from "react";
import DayPicker, { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";
import classnames from "classnames";
import moment from "moment";
import css from "./style.module.scss";

const Calendar = ({ setCalendarVisibly, setDateRange }) => {
  const [state, setState] = useState({
    from: undefined,
    to: undefined,
  });
  const { from, to } = state;

  const handleDayClick = (day) => {
    const range = DateUtils.addDayToRange(day, state);
    setState(range);
  };

  return (
    <>
      <div className={css.content}>
        <DayPicker
          showOutsideDays
          numberOfMonths={1}
          selectedDays={[from, { from, to }]}
          modifiers={state}
          onDayClick={(day) => {
            handleDayClick(day);
          }}
        />
        <div className={css.buttons}>
          <div
            className={classnames("button purple-inline", css.cancel)}
            onClick={() => {
              setCalendarVisibly((prev) => !prev);
            }}
          >
            Cancel
          </div>
          <div
            className={classnames("button purple", css.ok)}
            onClick={() => {
              const tmp = {
                from: moment(state.from),
                to: moment(state.to),
              };
              setDateRange(tmp);
              setCalendarVisibly((prev) => !prev);
            }}
          >
            OK
          </div>
        </div>
      </div>
    </>
  );
};
export default Calendar;
