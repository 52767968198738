import React, { useEffect, useState, useCallback } from "react";
import {Collapse, Spin} from "antd";
import classnames from "classnames";
import css from "./style.module.scss";
import ImageModal from "./ImageModal";
import useForgeViewer from "context/ForgeViewerContext";
import Toolkit from "components/ForgeViewer/Viewer.Toolkit";
import Markup3D from "components/Markup3D";

const { Panel } = Collapse;

const InformationPanel = ({ activeInformation }) => {
  const [image, setImage] = useState("");
  const [isImageShow, setIsImageShow] = useState(false);

  const onDownLoad = (file) => {
    console.log("DownLoad", file);
  };
  return (
    <>
      <ImageModal
        image={image}
        setIsImageShow={setIsImageShow}
        isImageShow={isImageShow}
      />
      <Collapse
        bordered={false}
        defaultActiveKey={-1}
        expandIcon={({ isActive }) => (
          <div
            className={classnames(
              css.expandIcon,
              {
                [css.active]: isActive,
              },
              {
                [css.notActive]: !isActive,
              }
            )}
          />
        )}
        className={classnames(css.collapse)}
      >
        {activeInformation?.data.map((info, i) => (
          <Panel
            header={<div className={css.header}>{info.name}</div>}
            key={i}
            className={classnames(css.itemPanel)}
          >
            {info.data.map((el, j) => (
              <div key={j} className={css.itemData}>
                <div>{el.name}</div>
                {el.type === "text" && (
                  <div className={css.text}>{el.value}</div>
                )}
                {el.type === "photo" && (
                  <img
                    className={css.photo}
                    src={el.value}
                    onClick={() => {
                      setIsImageShow(true);
                      setImage(el.value);
                    }}

                    alt=''
                  />
                )}
                {el.type === "file" && (
                  <div
                    className={css.file}
                    onClick={() => {
                      onDownLoad(el.value);
                    }}
                  />
                )}
              </div>
            ))}
          </Panel>
        ))}
      </Collapse>
    </>
  );
};

const ButtonsPanel = ({ markers, setShowInterestInfo, setActiveInformation }) => {
    const onClickHandler = (el) => {
        setShowInterestInfo(true);
        setActiveInformation(el);
        el.onClick()
    }

    useEffect(() => {
        markers.forEach((marker) => {
            marker.el.addEventListener('markerClickEvent', (e) => {
                setShowInterestInfo(true);
                setActiveInformation(e.detail);
            })
        })
    }, [markers])

  return (
    <>
      <div className={css.description}>
        Please select an object from the list below or click on the needed icon
        on the 3D model to view detailed information about it
      </div>
      <div>
        {markers.map((el, i) => (
          <div key={el.id}>
            <div
              className={css.button}
              onClick={() => onClickHandler(el)}
            >
              { el.markerName }
            </div>
            {i !== markers.length - 1 && <div className="divider" />}
          </div>
        ))}
      </div>
    </>
  );
};

const PointInterest = ({ setShowInterestInfo, showInterestInfo }) => {
  const [activeInformation, setActiveInformation] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [loading, setLoading] = useState(true)
  const { viewer } = useForgeViewer()


  const initMarkup = useCallback(async () => {

    const model = viewer.model
    const markerDbIds = await Toolkit.getComponentsByParentName( "Specialty Equipment", model)

    const markers = []
    for (const dbId of [...markerDbIds, 3829]) {

        try {
            const markerNameProperty = await Toolkit.getProperty(viewer.model, dbId, 'Ebuilidng point of interest')
          if(markerNameProperty.displayValue) {
            // console.log('markerName'.toUpperCase(), await Toolkit.getProperties(viewer.model, dbId))
            markers.push( new Markup3D(viewer, dbId, markerNameProperty.displayValue))
          }

        } catch (e) {
            // console.error(e.message)
        }

    }
    setMarkers(markers)
    setLoading(false)

  }, [viewer])


  useEffect(() => {
    initMarkup()

  }, [initMarkup])

  useEffect(() => {

    return () => {
      if(markers) {
        markers.forEach((marker) => marker.remove())
      }
    }
  }, [markers])

  useEffect(() => {
    if(!showInterestInfo) {
      viewer.clearSelection()
      viewer.fitToView()
    }

  }, [showInterestInfo])

  return (
    <>
      <div className={css.content}>
        {loading ? <Spin className={css.poiSpin} /> : showInterestInfo ? (
          <InformationPanel activeInformation={activeInformation} />
        ) : (
          <ButtonsPanel
            markers={markers}
            setShowInterestInfo={setShowInterestInfo}
            setActiveInformation={setActiveInformation}
          />
        )}
      </div>
    </>
  );
};

export default PointInterest;
