import axios from "axios";
import { BASE_API_URL } from "../../configs/base";

class DocumentsApi {
  setDocumentApi(action) {
    let formData = new FormData();
    formData.append(
      "fileData",
      JSON.stringify({
        buildId: action.file.buildId,
        date: action.file.date,
        description: action.file.description,
        extension: action.file.extension,
        name: action.file.name,
        documentType: action.documentType,
      })
    );
    formData.append("file", action.file.obj);

    return axios
      .post(`${BASE_API_URL}/api/build/documents`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => res.data);
  }
  getDocumentApi(action) {
    return axios
      .get(
        `${BASE_API_URL}/api/build/${action.buildId}/documents/${action.documentType}`
      )
      .then((res) => res.data);
  }
  renameDocumentApi(action) {
    let formData = new FormData();
    formData.append(
      "fileData",
      JSON.stringify({
        id: action.buildId,
        newName: action.newName,
        extension: action.file.extension,
        name: action.file.name,
        type: action.file.type,
      })
    );
    return axios
      .put(`${BASE_API_URL}/api/build/documents`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => res.data);
  }
  downloadDocumentApi(action) {
    return axios
      .get(
        `${BASE_API_URL}/api/download/build/${action.buildId}/type/${action.file.type}/documents/${action.file.name}/extension/${action.file.extension}`
      )
      .then((res) => res.data);
  }
  deleteDocumentApi(action) {
    return axios
      .delete(
        `${BASE_API_URL}/api/build/${action.buildId}/type/${action.file.type}/documents/${action.file.name}/extension/${action.file.extension}`
      )
      .then((res) => res.data);
  }
}
export default new DocumentsApi();
