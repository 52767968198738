import React from "react"
import Slider from "react-slick"
import classnames from "classnames"
import css from "./style.module.scss"

const CustomArrowRight = (props) => {
  const { onClick, currentSlide, slideCount } = props

  if (currentSlide >= (slideCount / 2.5).toFixed(0)) {
    return <></>
  } else {
    return (
      <div
        className={classnames(css.button, { [css.right]: true })}
        onClick={onClick}
      >
        <div className={classnames(css.icon)} />
      </div>
    )
  }
}
const CustomArrowLeft = (props) => {
  const { onClick, currentSlide } = props
  if (currentSlide > 0) {
    return (
      <div
        className={classnames(css.button, { [css.left]: true })}
        onClick={onClick}
      >
        <div className={classnames(css.icon, { [css.left]: true })} />
      </div>
    )
  } else {
    return <></>
  }
}

const settings = {
  dots: false,
  infinite: false,
  centerMode: false,
  speed: 200,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  nextArrow: <CustomArrowRight />,
  prevArrow: <CustomArrowLeft />,
}


const Levels = ({ levels, activeItem, onSelect }) => {

  return (
    <div className={css.list}>
      <Slider
        {...settings}
        className={classnames("slider variable-width", css.slider)}
      >
        {levels.map((el, i) => {
          return (
            <div
              key={i}
              className={classnames(css.item, {
                [css.active]: activeItem === i,
              })}
              onClick={() => {
                onSelect(i, el)
              }}
            >
              {el.name}
              {/*{activeItem === i ? " floor" : ""}*/}
            </div>
          )
        })}
      </Slider>
    </div>
  )
}

export default Levels
