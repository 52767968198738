import React, { useState } from "react";
import classnames from "classnames";
import { Popover } from "antd";
import css from "./style.module.scss";
import Calendar from "../Calendar";

const DropDown = ({
  options,
  setCurrentOption,
  currentOption,
  setDateRange,
}) => {
  const [showList, setShowList] = useState(false);
  const [calendarVisibly, setCalendarVisibly] = useState(false);
  const changeOption = (option) => {
    setCurrentOption(option);
  };
  const onShowList = () => {
    setShowList((prev) => !prev);
  };

  const dataRangeHandler = (data) => {
    setShowList((prev) => !prev)
    setDateRange(data)
    changeOption('Select')
  }
  return (
    <div className={classnames(css.content, { [css.whiteBorder]: showList })}>
      <div
        className={css.currentElement}
        onClick={() => {
          onShowList();
        }}
      >
        <div className={classnames(css.text, { [css.white]: showList })}>
          {currentOption}
        </div>
        <div className={classnames(css.arrow, { [css.rotate]: showList })} />
      </div>
      {showList && (
        <div className={css.list}>
          {options.map((el, i) => {
            if (el === "Select") {
              return (
                <Popover
                  visible={calendarVisibly}
                  overlayClassName={"Calendar"}
                  arrowPointAtCenter={false}
                  trigger="click"
                  placement="bottomLeft"
                  content={
                    <Calendar
                      setCalendarVisibly={setCalendarVisibly}
                      setDateRange={(data) => dataRangeHandler(data)}
                    />
                  }
                  key={i}
                >
                  <div
                    className={classnames(css.item, {
                      [css.hide]: el === currentOption,
                    })}
                    onClick={() => {
                      // onShowList();
                      // changeOption(el);
                      setCalendarVisibly((prev) => !prev);
                    }}
                  >
                    {el}
                  </div>
                </Popover>
              );
            }
            return (
              <div
                className={classnames(css.item, {
                  [css.hide]: el === currentOption,
                })}
                key={i}
                onClick={() => {
                  changeOption(el);
                  onShowList();
                }}
              >
                {el}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default DropDown;
