import React from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import css from "./style.module.scss";

const MenuItem = ({ icon, patch, name, isHidden }) => {
  const isActive = window.location.pathname === patch;
  return (
    <Link
      to={patch}
      className={classnames(
        css.link,
        { [css.active]: isActive },
        { [css.notActive]: !isActive }
      )}
    >
      <img
        src={isActive ? icon.purple : icon.white}
        className={css.icon}
        alt="icon"
      />
      {isHidden && <div className={css.text}>{name}</div>}
    </Link>
  );
};
export default MenuItem;
