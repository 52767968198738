export const GET_BUILDS_REQUEST = "GET_BUILDS_REQUEST";
export const GET_BUILDS_SUCCESS = "GET_BUILDS_SUCCESS";
export const GET_BUILDS_ERROR = "GET_BUILDS_ERROR";
export const SET_CURRENT_BUILDS = "SET_CURRENT_BUILDS";
export const SET_NOTIFICATION_REQUEST = "SET_NOTIFICATION_REQUEST";
export const SET_PRECAST = "SET_PRECAST";
export const SET_PRECAST_STATE = "SET_PRECAST_STATE";
export const SET_NOTIFICATION_ERROR = "SET_NOTIFICATION_ERROR";
export const SET_ALL_MARK_REQUEST = "SET_ALL_MARK_REQUEST";

export const getBuildsRequest = () => {
  return {
    type: GET_BUILDS_REQUEST,
  };
};

export const getBuildsSuccess = (data) => {
  return {
    type: GET_BUILDS_SUCCESS,
    data,
  };
};

export const getBuildsError = (error) => {
  return {
    type: GET_BUILDS_ERROR,
    error,
  };
};

export const setBuild = (index) => {
  return {
    type: SET_CURRENT_BUILDS,
    index,
  };
};

export const setNotificationReadRequest = (index, buildId, user) => {
  return {
    type: SET_NOTIFICATION_REQUEST,
    index,
    buildId,
    user,
  };
};

export const setNotificationReadError = (error) => {
  return {
    type: SET_NOTIFICATION_ERROR,
    error,
  };
};

export const setAllMarkRequest = (buildId, user) => {
  return {
    type: SET_ALL_MARK_REQUEST,
    buildId,
    user,
  };
};

export const setPrecast = (data) => {
  return {
    type: SET_PRECAST,
    data,
  };
};

export const setPrecastState = (data) => {
  return {
    type: SET_PRECAST_STATE,
    data,
  };
};
