const actions = {
  SET_STATE: 'user/SET_STATE',
  LOGIN: 'user/LOGIN',
  LOGOUT: 'user/LOGOUT',
}

export default actions

export const setStateActions = (data) => {
  return {
    type: actions.SET_STATE,
    payload: data,
  }
}
