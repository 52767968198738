const search = (fileName, initialState) => {
  if (fileName.length !== 0) {
    return [...initialState.materials].filter(
      (file) => file.name.indexOf(fileName) > -1
    );
  } else {
    return [...initialState.materials];
  }
};

export default search;
