import React, { useState } from "react";
import css from "./style.module.scss";

const Menu = ({ Component, setActiveButton, name }) => {
  const [showInterestInfo, setShowInterestInfo] = useState(false);
  return (
    <div className={css.content}>
      <div className={css.header}>
        {showInterestInfo && (
          <div
            className={css.backButton}
            onClick={() => {
              setShowInterestInfo(false);
            }}
          />
        )}
        <div className={css.text}>{name}</div>
        <div
          className={css.close}
          onClick={() => {
            setActiveButton(null);
          }}
        />
      </div>
      <Component
        setShowInterestInfo={setShowInterestInfo}
        showInterestInfo={showInterestInfo}
      />
    </div>
  );
};

export default Menu;
