import {all, select, put, takeLatest, call } from "redux-saga/effects";
import actions from '../actions/forgeActions'
import { loadToken } from 'services/forge'

const validateToken = (token) => !!token
    && (token.expires - 60) > (new Date().getTime() / 1000)


function* getToken({ payload }) {
  try {
    const { forge } = yield select();
    yield put({ type: actions.SET_STATE, payload: { loading: true, }, })
      let token = forge.token;

      if(!validateToken(token)) {
        const { data } = yield call(loadToken)

        token = { ...data, expires: (new Date().getTime() / 1000 + data.expires_in) }

        yield put({ type: actions.SET_STATE,  payload: { token: token }, })

      }

      sessionStorage.setItem('forge_token',  JSON.stringify(token))

      if(payload.callback) {
        payload.callback(token.access_token, token.expires_in)
      }

  } catch (error) {
    console.error(error)
  } finally {
    yield put({ type: actions.SET_STATE, payload: { loading: false } })
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(actions.LOAD_TOKEN, getToken)
  ])
}
