import React, { useState, useEffect } from "react";
import classNames from "classnames";
import css from "./style.module.scss";
import useForgeViewer from "context/ForgeViewerContext";


export const TableMaintenance = ({ data }) => {
  const [select, setSelect] = useState(null);
  const {viewer} = useForgeViewer()
  const onDownLoad = (e, file) => {
    e.preventDefault()
    e.stopPropagation()
    console.log("DownLoad", file);
  };

  const onClickHandler = (e, el) => {
    e.preventDefault()
    e.stopPropagation()
    if(select === el.task) {
      viewer.isolate([])
      viewer.fitToView([])
      setSelect(null)
    } else {
      viewer.isolate(el.dbIds)
      viewer.fitToView(el.dbIds)
      setSelect(el.task)
    }
  }

  useEffect(() => {

    return () => {
      if(viewer && viewer.impl) {
        viewer.isolate([])
        viewer.fitToView([])
      }
    }
  }, [viewer])


  return (
    <div>
      <table className={css.table}>
        <thead>
          <tr>
            <th>Code</th>
            <th>Task</th>
            <th>Result</th>
            <th>KRT/a</th>
            <th>2D</th>
          </tr>
        </thead>

        <tbody>
          {data.map((el, i) => (
            <tr key={i} className={classNames({[css.active]: el.task === select
            })} onClick={(e) => onClickHandler(e, el)}>
              <td width="63">{el.code}</td>
              <td width="207">{el.task}</td>
              <td width="120" className={css.result}>
                <div
                  className={classNames(
                    css.resultText,
                    {
                      [css.good]: el.result === "Good",
                    },
                    {
                      [css.bad]: el.result === "Bad",
                    }
                  )}
                >
                  {el.result}
                </div>
                <div className={css.date}>{el.date}</div>
              </td>
              <td width="71" align="right">
                {el.krt}
              </td>
              <td width="47" align="right">
                <div
                  className={css.file}
                  onClick={(e) => {
                    onDownLoad(e, el.download2d);
                  }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
