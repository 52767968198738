import React, {useState} from 'react'
import PropTypes from 'prop-types'

const ForgeViewerContext = React.createContext({})


export const ForgeViewerProvider  = ({ children }) => {

  const [loading, setLoading] = useState(true )
  const [viewer, setViewer] = useState( null )


  const setViewerHandler = (viewer) =>  {
    setViewer(viewer)
    setLoading(false)
  }

  return (
      <ForgeViewerContext.Provider value={{
        loading,
        viewer,
        setLoading,
       setViewer: setViewerHandler,
      }}> { children }
      </ForgeViewerContext.Provider>
  )
}

ForgeViewerProvider.propTypes = {
  children: PropTypes.element.isRequired
};

export default function useForgeViewer () {
  const context = React.useContext(ForgeViewerContext)

  if (context === undefined) {
    throw new Error(`useForgeViewer must be used within a ForgeViewerProvider`)
  }

  return context
}
