import React, { useState } from "react";
import { Modal, Upload, Row, Col } from "antd";
import classNames from "classnames";
import moment from "moment";
import css from "./style.module.scss";
import { useEffect } from "react";
const { Dragger } = Upload;

const getExtensionImg = (file) => {
  const extension = file.name.split(".").pop();
  let img = "";
  switch (extension) {
    case "doc" || "docx":
      img = "/assets/icons/documents/Doc.png";
      break;
    case "pdf":
      img = "/assets/icons/documents/Pdf.png";
      break;
    case "xlsx":
      img = "/assets/icons/documents/Excel.png";
      break;
    default:
      break;
  }
  return img;
};

const ModalContent = ({
  setDocumentRequest,
  buildId,
  setModalVisible,
  documentType,
  modalVisible,
}) => {
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [fileDescription, setFileDescription] = useState("");
  const [uploadError, setUploadError] = useState(false);
  useEffect(() => {
    setFileDescription("");
    setFileName("");
    setFile(null);
  }, [modalVisible]);
  const handleSubmit = () => {
    if (file && fileName && fileDescription) {
      const newFile = {
        obj: file,
        name: fileName,
        description: fileDescription,
        extension: file.name.split(".").pop(),
        buildId: buildId,
        date: moment().format("DD[.]MM[.]YYYY H:mm:ss"),
      };

      setDocumentRequest(newFile, documentType, buildId);
      setModalVisible(false);
    } else {
      setUploadError(true);
    }
  };
  return (
    <div className={css.modalContent}>
      <Row className={css.header} align="middle">
        <Col className={css.text} span={23}>
          Add document
        </Col>
        <Col span={1}>
          <div
            className={css.closeIcon}
            onClick={() => {
              setModalVisible(false);
            }}
          />
        </Col>
      </Row>

      <Row gutter={[24, 0]}>
        <Col span={10} className={css.dragger}>
          <div className={css.topText}>
            Attach file
            {uploadError && !file && <mark className={css.errorStar}>*</mark>}
          </div>
          <Dragger
            fileList={false}
            customRequest={({ file, onSuccess }) => {
              setTimeout(() => {
                onSuccess("ok");
              }, 0);
            }}
            beforeUpload={(file) => {
              setFile(file);
              setUploadError(false);
            }}
          >
            {file ? (
              <div
                className={classNames(css.text, { [css.textFileData]: true })}
              >
                <img alt="extension" src={getExtensionImg(file)} />
                <div className={css.top}>{file.name} </div>
              </div>
            ) : (
              <div className={css.text}>
                <div className={css.top}>Drop file here or </div>
                <div className={css.bottom}>Browse file</div>
              </div>
            )}
          </Dragger>
        </Col>
        <Col span={14}>
          <Row gutter={[0, 20]}>
            <Col span={24} className={css.inputForm}>
              <div className={css.topText}>
                Name
                {uploadError && !fileName && (
                  <mark className={css.errorStar}>*</mark>
                )}
              </div>
              <input
                placeholder="Name"
                className={css.input}
                value={fileName}
                onChange={(e) => {
                  setFileName(e.target.value);
                  setUploadError(false);
                }}
              />
            </Col>
            <Col span={24} className={css.inputForm}>
              <Row>
                <Col span={18} className={css.topText}>
                  Description
                  {uploadError && !fileDescription && (
                    <mark className={css.errorStar}>*</mark>
                  )}
                </Col>
                <Col span={6} className={css.textLength}>
                  {fileDescription.length}/200
                </Col>
              </Row>

              <textarea
                maxLength="200"
                value={fileDescription}
                onChange={(e) => {
                  setFileDescription(e.target.value);
                  setUploadError(false);
                }}
                placeholder="Description"
                className={classNames(css.input, { [css.textArea]: true })}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className={css.submitButton} onClick={handleSubmit}>
            Upload
          </div>
        </Col>
      </Row>
    </div>
  );
};

const UploadModal = ({
  modalVisible,
  setModalVisible,
  setDocumentRequest,
  buildId,
  documentType,
}) => {
  return (
    <Modal
      closable={false}
      visible={modalVisible}
      footer={false}
      onOk={() => {
        setModalVisible(false);
      }}
      onCancel={() => {
        setModalVisible(false);
      }}
    >
      <ModalContent
        setDocumentRequest={setDocumentRequest}
        buildId={buildId}
        setModalVisible={setModalVisible}
        documentType={documentType}
        modalVisible={modalVisible}
      />
    </Modal>
  );
};

export default UploadModal;
