import React from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { connect } from "react-redux";
import { hiddenMenu } from "../../../redux/actions/menuActions";
import css from "./style.module.scss";
import routers from "../../../router/routers";
import MenuItem from "./ItemLink";
const Menu = ({ menu, hiddenMenu }) => {
  return (
    <div>
      <div className={classnames(css.content, { [css.hidden]: !menu.isOpen })}>
        <div
          className={classnames(css.header, {
            [css.hiddenButton]: !menu.isOpen,
          })}
        >
          {menu.isOpen && (
            <Link to="/">
              <img
                className={css.logo}
                src="/assets/logo/LogoWhite.svg"
                alt="logo"
              />
            </Link>
          )}
          <div
            className={classnames(css.hiddeButton)}
            onClick={() => {
              hiddenMenu();
            }}
          >
            {menu.isOpen && (
              <img
                className={css.arrow}
                src="/assets/icons/arrows/ArrowRightSmallWhite.svg"
                alt="arrow"
              />
            )}
            <img
              className={css.menu}
              src="/assets/icons/menu/MenuWhite.svg"
              alt="menu"
            />
          </div>
        </div>
        <div className={css.list}>
          {routers.slice(0, 9).map((el, i) => (
            <MenuItem
              key={i}
              patch={el.path}
              icon={el.icon}
              name={el.name}
              isHidden={menu.isOpen}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    menu: state.menu,
  };
};

const mapDispatchToProps = (dispatch) => {
  return { hiddenMenu: () => dispatch(hiddenMenu()) };
};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
