/*global THREE*/
import React, {useState, useEffect, useCallback, useRef, useMemo} from "react";
import {useSelector, useDispatch} from "react-redux";
import { sortBy, union, sum } from 'lodash'
import {Spin} from "antd";
import { cloneDeep } from 'lodash'
import Toolkit from "components/ForgeViewer/Viewer.Toolkit";
import useForgeViewer from "context/ForgeViewerContext";
import {Table} from "./Table";
import {PieChart} from "./Chart";
import { setPrecast } from "../../../../redux/actions/buildsActions";
import css from "./style.module.scss";

const Precast = () => {
  const builds = useSelector((state) => state.builds)
  const currentBuild = builds.builds[builds.currentBuildId];
  const dispatch = useDispatch()

  const canvasRef = useRef(null)
  const [loading, setLoading] = useState(true)
  const [chart, setChart] = useState(null)
  const {viewer} = useForgeViewer()

  const init = useCallback(async () => {

    if(currentBuild?.precasts.length <= 0) {
      const model = viewer.model
      const results = await Toolkit.mapPrecastsByProp(model, 'Mark')
      dispatch(setPrecast({ id: currentBuild._id, precasts: results }))
    }

    setLoading(false);
  }, [viewer, canvasRef, currentBuild])


  useEffect(() => {
    init().then()
    return () => {
      if(viewer && viewer.impl) {
        viewer.isolate([])
        viewer.clearThemingColors()
      }
    }
  }, [init, viewer])

  const changeStatusHandler = (el, status) => {
    const deep = cloneDeep(currentBuild?.precasts)
    const index = currentBuild?.precasts.indexOf(el)
    if(index !== -1) {
      deep[index].status = status
      dispatch(setPrecast({id: currentBuild._id, precasts: deep}))
    }
  }

  useEffect(() => {
    if(viewer && viewer.impl) {
      viewer.clearThemingColors()

      const selector = viewer.impl.selector.getAggregateSelection()?.[0]

      const deep = cloneDeep(currentBuild?.precasts || [])
      const obj = deep.reduce((acc, item) => {
        if(acc.hasOwnProperty(item.status)) {
          acc[item.status] = union(acc[item.status], item.dbIds)
        } else {
          acc[item.status] = item.dbIds
        }
        return acc
      }, {});

      const isolateIds = union(obj.Designed, obj.Manufactured, obj.Delivered, obj.Assembled)

      viewer.isolate(isolateIds)

      if (obj['Manufactured']) {
        obj['Manufactured'].forEach((id) => {
          viewer.setThemingColor(id, new THREE.Vector4(0, 1, 0, 1))
        })
      }

      if (obj['Delivered']) {
        obj['Delivered'].forEach((id) => {
          viewer.setThemingColor(id, new THREE.Vector4(0.09, 0.85, 0.85, 1))
        })
      }

      if (obj['Assembled']) {
        obj['Assembled'].forEach((id) => {
          viewer.setThemingColor(id, new THREE.Vector4(0.99, 0.79, 0.48, 1))
        })
      }

      viewer.select(selector?.selection || [])

      console.log(isolateIds, viewer.getIsolatedNodes())
      // viewer.impl.sceneUpdated(true)

    }

  }, [viewer, currentBuild.precasts])

  useEffect(() => {
    const context = canvasRef?.current?.getContext("2d")
    if(chart) chart.destroy()
    setChart(PieChart(currentBuild.precasts, context))
  }, [currentBuild.precasts, canvasRef])


  const degree = useMemo(() => {
    const count = (acc, item) => {
      if (acc.hasOwnProperty(item.typeMark)) {
        acc[item.typeMark] += 1
      } else {
        acc[item.typeMark] = 1
      }
      return acc
    }

    const obj = currentBuild.precasts
        .filter((o) => o.status !== 'Empty')
        .reduce(count, {});

    const obj2 = currentBuild.precasts.reduce(count, {});
    const chartData = Object.values(obj);
    let total = sum(Object.values(obj2));

    return chartData.reduce((acc, el) => acc + (el * 100 / total.toFixed(0)), 0).toFixed(0)

  }, [currentBuild.precasts])

  return (
      <div className={css.content}>
        <div className={css.header}>Pie chart</div>
        { loading ? <Spin /> : null }
        <div className={css.chart}>
          <canvas ref={canvasRef} width="400" height="400"/>
          <div className={css.degree}>Degree of readiness - {degree}%</div>
        </div>

        <div className="divider" />
        <div className={css.header}>List of elements</div>
        { loading ? <Spin /> :
            <Table data={sortBy(currentBuild.precasts, ['level'])} changeStatus={changeStatusHandler} />
        }
      </div>
  );
};

export default Precast;
