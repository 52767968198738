export const SET_DOCUMENT_REQUEST = "SET_DOCUMENT_REQUEST";
export const SET_DOCUMENT_SUCCESS = "SET_DOCUMENT_SUCCESS";
export const SET_DOCUMENT_ERROR = "SET_DOCUMENT_ERROR";
export const GET_DOCUMENT_REQUEST = "GET_DOCUMENT_REQUEST";
export const GET_DOCUMENT_SUCCESS = "GET_DOCUMENT_SUCCESS";
export const GET_DOCUMENT_ERROR = "GET_DOCUMENT_ERROR";
export const DOWNLOAD_DOCUMENT_REQUEST = "DOWNLOAD_DOCUMENT_REQUEST";
export const DOWNLOAD_DOCUMENT_SUCCESS = "DOWNLOAD_DOCUMENT_SUCCESS";
export const DOWNLOAD_DOCUMENT_ERROR = "DOWNLOAD_DOCUMENT_ERROR";
export const RENAME_DOCUMENT_REQUEST = "RENAME_DOCUMENT_REQUEST";
export const RENAME_DOCUMENT_ERROR = "RENAME_DOCUMENT_ERROR";
export const DELETE_DOCUMENT_REQUEST = "DELETE_DOCUMENT_REQUEST";
export const DELETE_DOCUMENT_ERROR = "DELETE_DOCUMENT_ERROR";
export const SORT = "SORT";
export const SEARCH = "SEARCH";

export const setDocumentRequest = (file, documentType, buildId) => {
  return {
    type: SET_DOCUMENT_REQUEST,
    file,
    documentType,
    buildId,
  };
};

export const setDocumentSuccess = (files, documentType) => {
  return {
    type: SET_DOCUMENT_SUCCESS,
    files,
    documentType,
  };
};
export const setDocumentError = (error) => {
  return {
    type: SET_DOCUMENT_ERROR,
    error,
  };
};
export const getDocumentRequest = (documentType, buildId) => {
  return {
    type: GET_DOCUMENT_REQUEST,
    documentType,
    buildId,
  };
};

export const getDocumentSuccess = (files, documentType) => {
  return {
    type: GET_DOCUMENT_SUCCESS,
    files,
    documentType,
  };
};
export const getDocumentError = (error) => {
  return {
    type: GET_DOCUMENT_ERROR,
    error,
  };
};

export const downloadDocumentRequest = (buildId, file) => {
  return {
    type: DOWNLOAD_DOCUMENT_REQUEST,
    buildId,
    file,
  };
};
export const downloadDocumentSuccess = (downloadFile) => {
  return {
    type: DOWNLOAD_DOCUMENT_SUCCESS,
    downloadFile,
  };
};
export const downloadDocumentError = (error) => {
  return {
    type: DOWNLOAD_DOCUMENT_ERROR,
    error,
  };
};

export const renameDocumentRequest = (buildId, file, newName) => {
  return {
    type: RENAME_DOCUMENT_REQUEST,
    buildId,
    file,
    newName,
  };
};

export const renameDocumentError = (error) => {
  return {
    type: RENAME_DOCUMENT_ERROR,
    error,
  };
};

export const deleteDocumentRequest = (buildId, file) => {
  return {
    type: DELETE_DOCUMENT_REQUEST,
    buildId,
    file,
  };
};
export const deleteDocumentError = (error) => {
  return {
    type: DELETE_DOCUMENT_ERROR,
    error,
  };
};
export const sort = (sortType) => {
  return {
    type: SORT,
    sortType,
  };
};

export const search = (fileName) => {
  return {
    type: SEARCH,
    fileName,
  };
};
