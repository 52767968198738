import React, {useState, useEffect, useCallback} from "react";
import classnames from "classnames";
import css from "./style.module.scss";
import {Spin} from "antd";
import { TableAnalysis } from "./Table";
import { BarChart, PieChart } from "./Chart";
import Toolkit from "../../../ForgeViewer/Viewer.Toolkit";
import useForgeViewer from "../../../../context/ForgeViewerContext";
const testColors = ["#FECA7A", "#F79663", "#D84774", "#16D8D8", "#F24C88", "#00ACB3", "#FFFFFF"];

const AreaAnalysis = () => {
  const [showMore, setShowMore] = useState(false);
  const [selected, setSelected] = useState(null)
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true)
  const defaultTableLenght = 3;
  const {viewer} = useForgeViewer()

  const initArea = useCallback(async() => {
    const model = viewer.model

    const massIds = await Toolkit.getComponentsByParentName( "Mass", model)

    const data = [];

    let colorIndex = 0;
    for (const massId in massIds) {
      const massItem = await Toolkit.getProperty(model, massIds[massId], 'eBuilding_RA', 'null');
      if(!massItem.displayValue || massItem.displayValue === 'null') {
        continue;
      }

      const currentMass = data.find( el => el.key === massItem.displayValue)

      const itemArea = await Toolkit.getProperty(model, massIds[massId], 'Room Area', 0);
      if(currentMass){
        currentMass.pintaAla += itemArea.displayValue
        currentMass.ids.push(massIds[massId])
      } else {
        data.push({
          key: massItem.displayValue,
          tilatyyppi: massItem.displayValue,
          pintaAla: itemArea.displayValue,
          color: testColors[colorIndex++],
          ids: [massIds[massId]]
        })
      }
    }

    setData(data);
    setLoading(false)

  }, [viewer])

  const onClickHandler = (i) => {
    const el = data[i]
    if (selected === i) {
      setSelected(null)
      viewer.select([])
      viewer.isolate([])
      viewer.fitToView([])
    } else {
      setSelected(i)
      viewer.select(el.ids)
      viewer.isolate(el.ids)
      viewer.fitToView(el.ids)
    }
  }

  useEffect(() => {
    return () => {
      if(viewer && viewer.impl) {
        viewer.isolate([])
        viewer.select([])
        viewer.fitToView([])
      }
    }
  }, [viewer])

  useEffect(() => {
    let chartData = data.slice(0, showMore ? data.length : defaultTableLenght);
    if(chartData.length > 0) {
      BarChart(chartData, onClickHandler.bind(this));
      PieChart(chartData, onClickHandler.bind(this));
    }
  }, [data, showMore]);

  useEffect(() => {
    initArea().then()
  }, [initArea])

  return (
    <div className={css.content}>
      <div className={css.header}>Database</div>
      { loading ? <Spin /> : data &&
      <TableAnalysis
        data={data.slice(0, showMore ? data.length : defaultTableLenght)}
        selected={selected}
        onClick={onClickHandler}
      /> }

      <div
        className={css.more}
        onClick={() => {
          setShowMore((prev) => !prev);
        }}
      >
        <div className={css.text}>{showMore ? "Hide" : "More"}</div>
        <div className={classnames(css.icon, { [css.active]: showMore })} />
      </div>

      <div className="divider" />

      <div className={css.header}>Bar chart</div>
      <div id="barChart">
        <canvas id="barCanvas" width="400" height="200"></canvas>
      </div>

      <div className={css.header}>Pie chart</div>
      <div id="pieChart">
        <canvas id="pieCanvas" width="200" height="200"></canvas>
      </div>
    </div>
  );
};

export default AreaAnalysis;
