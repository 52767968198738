function compareA(a, b) {
  const objA = a.name.toUpperCase();
  const objB = b.name.toUpperCase();
  let comparison = 0;
  if (objA > objB) {
    comparison = 1;
  } else if (objA < objB) {
    comparison = -1;
  }
  return comparison;
}
function compareZ(a, b) {
  const objA = a.name.toUpperCase();
  const objB = b.name.toUpperCase();
  let comparison = 0;
  if (objA < objB) {
    comparison = 1;
  } else if (objA > objB) {
    comparison = -1;
  }
  return comparison;
}
function compareType(a, b) {
  const objA = a.extension.toUpperCase();
  const objB = b.extension.toUpperCase();
  let comparison = 0;
  if (objA > objB) {
    comparison = 1;
  } else if (objA < objB) {
    comparison = -1;
  }
  return comparison;
}

const sortByDate = (data, added) => {
  return [...data].sort((a, b) => {
    let date1Arr = a.date.split(/[\s,.:]+/);
    let date2Arr = b.date.split(/[\s,.:]+/);
    let date1Obj = new Date(
      +date1Arr[2],
      +date1Arr[1],
      +date1Arr[0],
      +date1Arr[3],
      +date1Arr[4],
      +date1Arr[5]
    );
    let date2Obj = new Date(
      +date2Arr[2],
      +date2Arr[1],
      +date2Arr[0],
      +date2Arr[3],
      +date2Arr[4],
      +date2Arr[5]
    );
    return added === "first" ? date1Obj - date2Obj : date2Obj - date1Obj;
  });
};

const sortByName = (data, firstLetter) => {
  return [...data].sort(firstLetter === "A" ? compareA : compareZ);
};

const sortByType = (data) => {
  return [...data].sort(compareType);
};

const sort = (data, sortType) => {
  switch (sortType) {
    case 0:
      return [...sortByDate(data, "first")];
    case 1:
      return [...sortByDate(data, "last")];
    case 2:
      return [...sortByName(data, "A")];
    case 3:
      return [...sortByName(data, "Z")];
    case 4:
      return [...sortByType(data)];
    default:
      return [...data];
  }
};

export default sort;
