import axios from 'axios'
import qs from 'qs'

export function loadToken() {

  console.log('LOAD DATA')
  const data = qs.stringify({
    client_id:  process.env.REACT_APP_FORGE_CLIENT_ID,
    client_secret: process.env.REACT_APP_FORGE_CLIENT_SECRET,
    grant_type: process.env.REACT_APP_FORGE_GRANT_TYPE,
    scope: process.env.REACT_APP_FORGE_SCOPE
  })

  return axios.post(
      "https://developer.api.autodesk.com/authentication/v1/authenticate",
      data,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
  )

}
