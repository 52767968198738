import moment from "moment";
import { sortBy } from 'lodash'

const getRandomInt = (min, max) => {
  let random = (Math.random() * (max - min)) + min
  return Math.floor(random)
}

const getDates = (start, end) => {
  const dateArray = [];
  let startDate = typeof start  === 'string' ?  moment(start, "DD.MM.YYYY") : start
  if(!end) {
    end = moment()
  }

   while (startDate <= end) {
    dateArray.push(startDate);
     startDate = moment(startDate).add(getRandomInt(1, 3), "hours");
  }

  return sortBy(dateArray, (a, b) => a > b);
}

const getRandomSensors = (count, normalValue) => {
  const datesArr = getDates("12.10.2020");
  const arrNames = [...Array(count).keys()];
  let sensorsArr = [];

  arrNames.forEach((el) => {
    let resultsArr = [];
    datesArr.forEach((date) => {

      let rnd = getRandomInt(normalValue - 15, normalValue + 15)
      resultsArr.push({
        date: date,
        value: rnd,
      });

    });

    const hasError = resultsArr.reduce((a, i) => i.value <= normalValue , false)
    sensorsArr.push({
      name: el + 1,
      normalValue: normalValue,
      hasError,
      color: hasError ? [87,192,156]:[237,109,101],
      results: [...resultsArr],
    });
  });

  return sensorsArr;
}

export const getIotData = () => {
  return [
    {
      name: "Roof sensors",
      zone: "A",
      normalValue: "20",
      valueType: "C",
      sensors: getRandomSensors(2, 20),
    },
    {
      name: "Heating sensors",
      zone: "A",
      normalValue: "20",
      valueType: "Pa",
      sensors: getRandomSensors(4, 20),
    },
    {
      name: "Water sensors",
      zone: "A",
      normalValue: "20",
      valueType: "C",
      sensors: getRandomSensors(3, 20),
    },
    {
      name: "Electricity sensors",
      zone: "A",
      normalValue: "20",
      valueType: "C",
      sensors: getRandomSensors(3, 20),
    },
  ];
};
