import React from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import { useSelector } from 'react-redux'
import routers from "./routers";
import ScrollToTop from "../utils/scrollToTop";
import Layout from "../components/Layout";
import Login from "../components/Login";
const Router = () => {
  return (
    <BrowserRouter>
      <>
        <ScrollToTop />
        <Switch>
          {routers.map((route, idx) => (
            <RouterWrapper
              key={idx}
              path={route.path}
              component={route.component}
              layout={Layout}
              useMenu={route.useMenu}
              exact
              useLayout={route.useLayout}
            />
          ))}
        </Switch>
      </>
    </BrowserRouter>
  );
};

function RouterWrapper({
  component: Component,
  layout: Layout,
  useLayout,
  useMenu,
  ...rest
}) {

  const user = useSelector((state) => state.user)

  if ((new Date()).getTime() > user.expiry) {
    return <Login />
  }

  return (
    <>
      <Route {...rest}>
        {useLayout ? (
          <Layout useMenu={useMenu}>
            <Component />
          </Layout>
        ) : (
          <Component />
        )}
      </Route>
    </>
  );
}
export default Router;
