import React, {useState, useEffect, useCallback} from "react";
import classnames from "classnames";
import {Select, Input, Spin} from "antd";
import {sample, findIndex} from 'lodash'
import css from "./style.module.scss";
import {TableMaintenance} from "./Table";
import Toolkit from "components/ForgeViewer/Viewer.Toolkit";
import useForgeViewer from "context/ForgeViewerContext";

const {Option} = Select;

const dorpDown1 = ["Part 1", "Part 2", "Part 3 "];
const dorpDown2 = ["Good", "Bad"];

const Maintenance = () => {
  const [showMore, setShowMore] = useState(false);
  const [maintenances, setMaintenances] = useState([]);
  const [loading, setLoading] = useState(true)
  const {viewer} = useForgeViewer()

  const initMaintenance = useCallback(async() => {
    const model = viewer.model
    const dbIds =  await Toolkit.getLeafNodes(model)

    const props = await Toolkit.getBulkPropertiesAsync(model, dbIds, 'eBuilding_Maintenance')

    const maintenances = props.reduce((acc, val) => {
      const key = val?.properties?.[0]?.displayValue
      if(!!key) {
        const index = findIndex(acc, {task: key})
        index !== -1
          ? acc[index].dbIds.push(val.dbId)
          : acc.push({
              code: sample(["H", "H0", "H.01", "H.02", "H.03",  "H.04", "H.05"]),
              task: key,
              date: "27.07.2020",
              result: sample(dorpDown2),
              krt: sample([1, '', 2]),
              download2d: "",
              dbIds: [val.dbId],
            })
      }

      return acc
    }, [])

    setMaintenances(maintenances)
    setLoading(false);
  }, [viewer])

  useEffect(() => {
    initMaintenance().then()
  }, [initMaintenance])

  return (
      <div className={css.content}>
        <div className={css.header}>Revisions</div>
        { loading ? <Spin /> :
          <TableMaintenance
              data={maintenances.slice(0, showMore ? maintenances.length : 3)}
          />
        }

        <div
            className={css.more}
            onClick={() => {
              setShowMore((prev) => !prev);
            }}
        >
          <div className={css.text}>{showMore ? "Hide" : "More"}</div>
          <div className={classnames(css.icon, {[css.active]: showMore})}/>
        </div>

        <div className="divider"/>
        <div className={css.header}>New revision</div>

        <div className={css.dropDowns}>
          <div className={css.dropdownItem}>
            <div className={css.text}>Part</div>
            <Select placeholder="Select a part #" className={css.dropdown}>
              {dorpDown1.map((el, i) => (
                  <Option key={i} value={el}>
                    {el}
                  </Option>
              ))}
            </Select>
          </div>

          <div className={css.dropdownItem}>
            <div className={css.text}>Status</div>
            <Select placeholder="Select a result" className={css.dropdown}>
              {dorpDown2.map((el, i) => (
                  <Option key={i} value={el}>
                    {el}
                  </Option>
              ))}
            </Select>
          </div>
        </div>
        <div className={css.input}>
          <div className={css.text}>Status</div>
          <Input placeholder="What you recommend to do"/>
        </div>
        <div className={css.submit}>Submit</div>
      </div>
  );
};

export default Maintenance;
