import React, {useEffect, useState} from "react";
import {Input, Spin} from "antd";
import classnames from "classnames";
import Levels from "./Levels";
import css from "./style.module.scss";
import useLevels  from 'hooks/useLevels'
import useApartment  from 'hooks/useApartment'
import useForgeViewer from "context/ForgeViewerContext";

const QuickAccess = () => {

  const [showMore, setShowMore] = useState(false);
  const [search, setSearch] = useState('');
  const { viewer } = useForgeViewer()
  const { levels, activeLevel, selectLevel, levelsLoading } = useLevels(viewer)
  const {
    apartments,
    activeApartment,
    selectApartments,
    apartmentsSelectorFilter,
    clearApartment,
    apartmentLoading,
  } = useApartment(viewer)

  const handlerOnInput = (value) => {
    setSearch(value)
  }

  const handlerOnSelectLevel = (i , level) => {
    clearApartment()
    selectLevel(i , level)
    setSearch('')
  }

  const filterData = () => {
    apartmentsSelectorFilter([
      {
        handler: (data) => data.filter((item) =>  item.name.startsWith(search))
      },
      {
        handler: !levels[activeLevel]
            ? (data) => data.filter((item) => item)
            : (data) => data.filter((item) => item.floor === levels[activeLevel].name)
      }
    ])
  }

  useEffect(filterData, [activeLevel, search])

  return (
    <div className={css.content}>
      <div className={css.search}>
        <div className={css.text}>Search</div>
        <Input
          size="large"
          value={search}
          onChange={(e) => handlerOnInput(e.target.value)}
          placeholder="Search an apartment e.c A323"
          prefix={<img src="/assets/icons/model/Search.svg" alt="" />}
        />
      </div>
      <div className="divider" />
      <div className={css.roomsList}>
        <div className={css.text}>Rooms list</div>
        {levelsLoading ? <Spin /> : <Levels levels={levels} activeItem={activeLevel} onSelect={handlerOnSelectLevel} /> }
      </div>
      <div className={css.apartmentList}>
        <div className={css.textHeader}>
          <div>Apartment</div>
          <div>Info</div>
        </div>
        {apartmentLoading ? <Spin className={css.qaSpin} /> : apartments.slice(0, showMore ? apartments.length : 3 )
            .map((el, i) => (
            <div
                className={classnames(css.listItem, {
                  [css.active]: activeApartment === i,
                })}
                key={i}
                onClick={() => selectApartments(i, el)}>
              <div>{el.name}</div>
              <div>{el.info}</div>
            </div>
        ))}
        <div
          className={css.more}
          onClick={() => {
            setShowMore((prev) => !prev);
          }}
        >
          <div className={css.text}>{showMore ? "Hide" : "More"}</div>
          <div className={classnames(css.icon, { [css.active]: showMore })} />
        </div>
      </div>
      <div className="divider" />
    </div>
  );
};

export default QuickAccess;
