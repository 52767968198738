import React, { useState } from "react";
import classNames from "classnames";
import css from "./style.module.scss";
import { BASE_API_URL } from "../../../../configs/base";

const NotificationItem = ({
  item,
  user,
  buildId,
  setNotificationReadRequest,
  isAllRead,
}) => {
  let isReader = item.isRead.find((item) => item._id === user.id);
  const [readItem, setReadItem] = useState(isReader);

  return (
    <div
      className={classNames(
        css.item,
        { [css.error]: item.type === "error" },
        {
          [classNames(
            css.message,
            { [css.read]: readItem || isAllRead },
            { [css.notRead]: !readItem && item.type === "message" }
          )]: true,
        }
      )}
      onClick={() => {
        setReadItem(true);
        setNotificationReadRequest(item._id, buildId, user);
      }}
    >
      <img
        src={
          item.user
            ? BASE_API_URL + item.user.photo
            : "/assets/icons/notifications/Attention.png"
        }
        className={css.icon}
        alt="icon"
      />

      <div className={css.description}>
        <div className={css.top}>
          {item.user ? item.user.name : ""} {item.user ? item.user.sName : ""}
          {item.description}
        </div>
        <div className={css.bottom}>
          {item.date ? item.date : item.errorMessage}
        </div>
      </div>
      <div hidden={readItem || isAllRead} className={classNames(css.circle)} />
    </div>
  );
};

const Notifications = ({
  notifications,
  user,
  setNotificationReadRequest,
  buildId,
  setAllMarkRequest,
}) => {
  const [isAllRead, setIsAllRead] = useState(false);
  return (
    <div className={css.content}>
      <div className={css.header}>
        <div className={css.text}>Notifications</div>
        <div
          className={css.markAll}
          onClick={() => {
            setIsAllRead(true);
            setAllMarkRequest(buildId, user);
          }}
        >
          Mark all as read
        </div>
      </div>
      <div className={css.list}>
        {notifications.map((el, i) => {
          return (
            <NotificationItem
              key={i}
              item={el}
              user={user}
              setNotificationReadRequest={setNotificationReadRequest}
              buildId={buildId}
              isAllRead={isAllRead}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Notifications;
