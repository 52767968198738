import React, { useState, useEffect } from "react";
import { Row, Col, Collapse } from "antd";
import classnames from "classnames";
import Sensor from "./Sensor";
import css from "./style.module.scss";
import { getIotData } from "data/modelData/iot";

const { Panel } = Collapse;

const ifSensorsHaveError = (sensors) => {
  return sensors.reduce((a, s) => a && s.hasError, true)
};

const Iot = () => {
  const [activePanel, setActivePanel] = useState(["0", "1", "2", "3"]);
  const [data, setData] = useState([]);
  const tempError = "Pipe pressure in the lobby dropped below normal";

  useEffect(() => {
    setData(getIotData());
  }, []);

  return (
    <div className={css.content}>
      <div className={css.header}>Sensors</div>

      <div className={css.sensorsHeader}>
        <Row>
          <Col md={8}>Name</Col>
          <Col md={5}>Zone</Col>
          <Col md={5}>Quantity</Col>
          <Col md={5}>Status</Col>
        </Row>
      </div>

      <Collapse
        className={css.collapse}
        defaultActiveKey={activePanel}
        bordered={false}
        onChange={setActivePanel}
        expandIcon={({ isActive }) => (
          <div
            className={classnames(
              css.expandIcon,
              {
                [css.active]: isActive,
              },
              {
                [css.notActive]: !isActive,
              }
            )}
          />
        )}
      >
        {data.map((el, i) => {
          return (
            <Panel
              header={
                <div
                  className={classnames(css.header, {
                    [css.active]: activePanel.includes(i.toString()),
                  })}
                >
                  <Row style={{ width: "100%" }}>
                    <Col md={8}>{el.name}</Col>
                    <Col md={5}>Zone {el.zone}</Col>
                    <Col md={5}>{el.sensors.length} sensors</Col>
                    <Col md={5} className={css.sensorStatus}>
                      {
                        <div
                          className={
                            !ifSensorsHaveError(el.sensors, el.normalValue)
                              ? css.error
                              : css.ok
                          }
                        />
                      }
                    </Col>
                  </Row>
                </div>
              }
              key={i}
              className={css.sensor}
            >
              <Sensor
                tempError={
                  !ifSensorsHaveError(el.sensors, el.normalValue)
                    ? tempError
                    : ""
                }
                sensors={el.sensors}
                normalValue={el.normalValue}
                valueType={el.valueType}
              />
            </Panel>
          );
        })}
      </Collapse>
    </div>
  );
};

export default Iot;
