import {useCallback, useEffect, useRef, useState} from 'react'
import Toolkit from "components/ForgeViewer/Viewer.Toolkit";


export default function useApartment(viewer) {
  const [apartmentsData, setApartmentsData] = useState([])
  const [apartments, setApartments] = useState([])
  const [activeApartment, setActiveApartment] = useState(null)
  const prevActiveApartment = useRef(activeApartment);
  const [loading, setLoading] = useState(true)


  const clear = useCallback(() => {
    viewer.clearSelection()
    viewer.fitToView()
    setActiveApartment(null)
    prevActiveApartment.current = null
    // eslint-disable-next-line
  }, [apartmentsData, viewer, activeApartment])

  const initApartment = useCallback(async () => {
      const model = viewer.model
      const apartmentsIds = await Toolkit.getComponentsByParentName( "Generic Models", model)

    const apartments = [];
    for (let i = 0; i < apartmentsIds.length; i++) {
      const nameProps = await Toolkit.getProperty(model, apartmentsIds[i], 'eBuilding_QA_text', 'null')
      const infoProps = await Toolkit.getProperty(model,  apartmentsIds[i], 'eBuilding_QA_info', 'null')
      const floorProps = await Toolkit.getProperty(model,  apartmentsIds[i], 'eBuilding_QA_floor', 'null')
        if(nameProps.displayValue !== '' && nameProps.displayValue !== 'null') {
          apartments.push({
            name: nameProps.displayValue,
            info: infoProps.displayValue,
            dbId:  apartmentsIds[i],
            floor: floorProps.displayValue?.trim(),
            bounding: []
          })
        }
    }
      setApartments(apartments)
      setApartmentsData(apartments)
      setLoading(false)

  }, [viewer])

  const apartmentsSelectorFilter = (filters = []) => {
    const data = filters.reduce((curr, filter) => filter.handler ? filter.handler(curr) :  curr, [...apartmentsData])
    return setApartments(data)
  }

  const selectApartments = useCallback(async (index, apartment) => {

    if(activeApartment === index) return clear()

    const bounds = await Toolkit.getWorldBoundingBox(viewer.model, apartment.dbId)
    viewer.navigation.fitBounds(false, bounds.expandByScalar(10))
    setActiveApartment(index)
    prevActiveApartment.current = index
    // eslint-disable-next-line
  }, [viewer, activeApartment])

  // const selectApartments = (i, apartment) => activeApartment === i ? clear() : viewer.select(apartment.dbId)

  // const initEvent = useCallback(async ({selections}) => {
  //
  //   if(selections.length) {
  //     const selectApartment =  apartments.filter((apt) =>  selections[0].dbIdArray.includes(apt.dbId))
  //     if(selectApartment.length) {
  //       const apartment = selectApartment[0]
  //       const index =  apartments.indexOf(apartment)
  //       await changeSelection(apartment, index)
  //     } else {
  //       clear()
  //     }
  //
  //   } else if(activeApartment) {
  //     clear()
  //   }
  //   // eslint-disable-next-line
  // }, [apartments, activeApartment])


  // useEffect(() => {
  //   viewer.addEventListener(
  //       Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT,
  //       initEvent)
  //
  //   return () => viewer.removeEventListener(
  //       Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT,
  //       initEvent)
  // })

  useEffect(() => {
    initApartment()

  }, [initApartment])

  useEffect(() => {
    return () => {
      if(prevActiveApartment.current !== activeApartment || (prevActiveApartment.current === null && activeApartment ===null)) {
        return;
      }
      clear()
    }
  }, [clear, activeApartment])

  return {
    apartments,
    apartmentsData,
    activeApartment,
    clearApartment: clear,
    selectApartments,
    apartmentLoading: loading,
    apartmentsSelectorFilter
  }
}
