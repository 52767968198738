import React, { useState } from "react";
import { Input } from "antd";
import classNames from "classnames";
import { useDispatch } from 'react-redux'
import actions from "../../redux/actions/userActions";
import css from "./style.module.scss";

const Login = () => {
  const [inputVal, setInputVal] = useState("");
  const dispatch = useDispatch()

  const getAccess = () => {
    dispatch({ type: actions.LOGIN,  password: inputVal })
  };

  return (
      <div className={css.content}>
        <div className={css.title}>COMING SOON</div>
        <div className={css.subTitle}>Our website is under construction!</div>
        <div className={css.inputWrap}>
          <Input
              placeholder="password"
              type="password"
              value={inputVal}
              onChange={(e) => setInputVal(e.target.value)}
          />
          <div
              className={classNames(css.button, "button purple")}
              onClick={getAccess}
          >
            Get access
          </div>
        </div>
      </div>
  )
};

export default Login;
