import Chart from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

const getPercentage = (currentVal, defaultVal) =>
  (currentVal * 100) / defaultVal;

const reducer = (accumulator, currentValue) => accumulator + currentValue;

const randomColor = () => `#${Math.floor(Math.random()*16777215).toString(16)}`.toUpperCase();

const generateColors = (len = 0) => {
  const colors = ["#feca7a", "#F79663", "#d84774", "#16d8d8","#0975b0", "#00ACB3", "#6fe049"];
  const getColor = () => {
    let color = '#FFFFFF'
    do {
      color = randomColor()
    } while (colors.includes(color))

    return color
  }

  for (let i = colors.length; i < len; i++) {
    colors.push(getColor())
  }

  return colors.slice(0, len)
}



export const PieChart = (data, ctx) => {

  const obj = data.reduce((acc, item) => {
    acc[item.typeMark] ? acc[item.typeMark]++ : acc[item.typeMark]=1
    return acc
  }, {});

  const total = Object.values(obj).reduce(reducer, 0);

  const groupObj = Object.keys(obj).reduce((acc, key) => {
    const v = getPercentage(obj[key], total).toFixed(0)
    if (v < 5) {
      acc['Other'] ? acc['Other'] += Number(v) : acc['Other'] =  Number(v)
    } else {
      acc[key] = Number(v)
    }
    return acc
  }, {});

  const ordered = Object.keys(groupObj).sort((a, b) => {
    if (a === 'Other' || b === 'Other') return  -1
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  }).reduce(
      (obj, key) => {
        obj[key] = groupObj[key];
        return obj;
      },
      {}
  );


  let chartData = Object.values(ordered)
  let labels = Object.keys(ordered)
  let colors = generateColors(chartData.length);

  new Chart(ctx, {
    type: "doughnut",
    plugins: [ChartDataLabels],

    data: {
      labels,

      datasets: [
        {
          data: chartData,
          backgroundColor: colors,
          borderWidth: 0,
          weight: 20,
          borderColor: colors,
          barPercentage: 0.7,
          categoryPercentage: 1,
        },
      ],
    },

    options: {
      cutoutPercentage: 60,
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        datalabels: {
          formatter: (value) => value + "%",
          color: "#FFFFFF",
        },
      },

      legend: {
        position: "right",
        align: "center",
        fullWidth: false,

        labels: {
          fontColor: "#ffffff",
          fontSize: 14,
          usePointStyle: true,
          boxWidth: 4,
        },
      },
      scales: {
        yAxes: [
          {
            display: false,
          },
        ],

        xAxes: [
          {
            gridLines: {
              display: false,
            },

            ticks: {
              display: false,
            },
          },
        ],
      },
    },
  });
};
