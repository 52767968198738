/*global Autodesk*/
import React from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import Toolkit from "./Viewer.Toolkit";
import actions from 'redux/actions/forgeActions'


class ForgeViewer extends React.PureComponent {


  constructor(props) {
    super(props);
    this.onModelRootLoaded = this.onModelRootLoaded.bind(this)
    this.onGeometryLoaded = this.onGeometryLoaded.bind(this)
  }

  // async componentDidUpdate(prevProps, prevState, snapshot) {
  //
  //   if(prevProps.model !== this.props.model) {
  //
  //       try {
  //         this.unmount()
  //         await this.initialize({
  //           env: 'AutodeskProduction',
  //           getAccessToken: this.props.getForgeToken
  //         })
  //
  //         // Autodesk.Viewing.Private.memoryOptimizedSvfLoading = true
  //
  //       } catch (ex) {
  //         console.error(ex)
  //       }
  //   }
  // }

  componentWillUnmount () {
    this.unmount()
  }

  async componentDidMount () {
    try {

      await this.initialize({
        env: 'AutodeskProduction',
        getAccessToken: this.props.getForgeToken
      })

      Autodesk.Viewing.Private.memoryOptimizedSvfLoading = true

    } catch (ex) {
      console.error(ex)
    }
  }

  initialize (options) {
    return new Promise((resolve, reject) => {
      Autodesk.Viewing.Initializer(options, () => {
        this.initViewer(this.props.model)
            .then((viewer) =>  resolve (viewer))
      }, (error) => {
        reject (error)
      })
    })
  }

  onModelRootLoaded (event) {
    const viewer = event.target
    viewer.removeEventListener(
        Autodesk.Viewing.MODEL_ROOT_LOADED_EVENT,
        this.onModelRootLoaded)

    const nav = viewer.navigation

    // TODO you need to come up with something else
    // Toolkit.tweenCameraTo(viewer, vstates[0])


    nav.toPerspective()
     viewer.autocam.setHomeViewFrom(
         nav.getCamera())

    nav.setReverseZoomDirection(true)

    viewer.autocam.shotParams.destinationPercent = 3;
    viewer.autocam.shotParams.duration = 3;

    viewer.setLightPreset(18)

  }

  async onGeometryLoaded (event) {
    const viewer = event.target
    viewer.removeEventListener(
        Autodesk.Viewing.GEOMETRY_LOADED_EVENT,
        this.onGeometryLoaded)

    setTimeout(async () => {
      const massIds = await Toolkit.getComponentsByParentName( "Mass", viewer.model)

      await Toolkit.hide(viewer, massIds)
    }, 1000)

    setTimeout(() => {
      if (viewer.viewCubeUi) {
        viewer.showViewCubeTriad(true)
      }
    }, 2000)

    if (this.props.onViewerCreated) {
      this.props.onViewerCreated(viewer)
    }
  }

  async initViewer (model) {
    const config = {
      extensions: [
        "Autodesk.AEC.LevelsExtension",
        "Autodesk.DocumentBrowser",
        // "Autodesk.AEC.Minimap3DExtension"
      ]
    }

    this.viewer = new Autodesk.Viewing.Private.GuiViewer3D(this.viewerContainer, config)

    this.viewer.start()
    this.viewer.addEventListener(
        Autodesk.Viewing.MODEL_ROOT_LOADED_EVENT,
        this.onModelRootLoaded)

    this.viewer.addEventListener(
        Autodesk.Viewing.GEOMETRY_LOADED_EVENT,
        this.onGeometryLoaded)

    // viewer.addEventListener(Autodesk.Viewing.CAMERA_CHANGE_EVENT,
    //    (e) => {
    //      const v = e.target;
    //      console.log('CAMERA_CHANGE_EVENT', v.getState())
    //
    //
    // })

    this.viewer.prefs.tag('ignore-producer')

    this.viewer.setBackgroundColor(
        205, 205, 205,
        255, 255, 255)

    const doc = await Toolkit.loadDocument(model.urn, this.viewer)
    this.viewer.AecModelData = await doc.downloadAecModelData()

    // const viewables = Toolkit.getViewableItems(this.viewerDocument)

    const viewables = doc.getRoot().getDefaultGeometry();

    await this.viewer.loadDocumentNode(doc, viewables)

    // const path = Toolkit.getViewablePath(doc)

    // const loadOptions = {
    //   sharedPropertyDbPath:
    //       doc.getFullPath(doc.getRoot().findPropertyDbPath()),
    //   placementTransform: Toolkit.buildTransform(),
    //   [model.globalOffset ? 'globalOffset' : undefined]:model.globalOffset
    // }
    //
    // viewer.loadModel(path, loadOptions, (model) => {
    //   viewer.activeModel = model
    //   if (this.props.onModelLoaded) {
    //     this.props.onModelLoaded(model)
    //   }
    // })

    return this.viewer
  }

  unmount() {

    if (this.viewer) {
      this.viewer.finish()
      this.viewer.removeEventListener(
          Autodesk.Viewing.MODEL_ROOT_LOADED_EVENT,
          this.onModelRootLoaded)

      this.viewer.removeEventListener(
            Autodesk.Viewing.GEOMETRY_LOADED_EVENT,
            this.onGeometryLoaded)
      this.viewer = null
      }
  }

  render () {
    return (
        <div className="viewer-app-container">
          <div
            ref={(div) => this.viewerContainer = div}
            className="viewer-container"
          />
        </div>
    )
  }

}

const mapDispatchToProps = dispatch => {
  return {
    getForgeToken: (cb) => dispatch({type: actions.LOAD_TOKEN, payload: {callback: cb } }),
  }
}

ForgeViewer.propTypes = {
  onViewerCreated: PropTypes.func,
  onModelLoaded: PropTypes.func,
  model: PropTypes.object.isRequired
}

export default connect(null, mapDispatchToProps)(ForgeViewer)
