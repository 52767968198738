// import Login from "../pages/Login";
import ChatPage from "../pages/Chat";
import Document from "../pages/Document";
import Financial from "../pages/Financial";
import Guides from "../pages/Guides";
import Shared from "../pages/Shared";
import Interactive from "../pages/Interactive";
import Iot from "../pages/Iot";
import User from "../pages/User";
import Maintenance from "../pages/Maintenance";
import BuildModelPage from "../pages/BuildModel";
import NotFound from "../pages/NotFound";
const routers = [
  {
    path: "/",
    component: Interactive,
    useLayout: true,
    useMenu: true,
    name: "Interactive model",
    icon: {
      purple: "/assets/icons/menu/Interactive_model.svg",
      white: "/assets/icons/menu/Interactive_modelWhite.svg",
    },
  },

  {
    path: "/document",
    component: Document,
    useLayout: true,
    useMenu: true,
    name: "Documents",
    icon: {
      purple: "/assets/icons/menu/Documents.svg",
      white: "/assets/icons/menu/DocumentsWhite.svg",
    },
  },

  {
    path: "/user",
    component: User,
    useLayout: true,
    useMenu: true,
    name: "User management",
    icon: {
      purple: "/assets/icons/menu/User.svg",
      white: "/assets/icons/menu/UserWhite.svg",
    },
  },
  {
    path: "/guides",
    component: Guides,
    useLayout: true,
    useMenu: true,
    name: "Guides & Rules",
    icon: {
      purple: "/assets/icons/menu/Guides.svg",
      white: "/assets/icons/menu/GuidesWhite.svg",
    },
  },
  {
    path: "/chat",
    component: ChatPage,
    useLayout: true,
    useMenu: true,
    name: "Chat",
    icon: {
      purple: "/assets/icons/menu/Chat.svg",
      white: "/assets/icons/menu/ChatWhite.svg",
    },
  },
  {
    path: "/maintenance",
    component: Maintenance,
    useLayout: true,
    useMenu: true,
    name: "Maintenance",
    icon: {
      purple: "/assets/icons/menu/Maintenance.svg",
      white: "/assets/icons/menu/MaintenanceWhite.svg",
    },
  },
  {
    path: "/iot",
    component: Iot,
    useLayout: true,
    useMenu: true,
    name: "IoT",
    icon: {
      purple: "/assets/icons/menu/IOT.svg",
      white: "/assets/icons/menu/IOTWhite.svg",
    },
  },
  {
    path: "/shared",
    component: Shared,
    useLayout: true,
    useMenu: true,
    name: "Shared spaces booking",
    icon: {
      purple: "/assets/icons/menu/Shared.svg",
      white: "/assets/icons/menu/SharedWhite.svg",
    },
  },
  {
    path: "/financial",
    component: Financial,
    useLayout: true,
    useMenu: true,
    name: "Financial",
    icon: {
      purple: "/assets/icons/menu/Financial.svg",
      white: "/assets/icons/menu/FinancialWhite.svg",
    },
  },
  // {
  //   path: "/login",
  //   component: Login,
  //   useLayout: false,
  //   name: "Login",
  // },
  {
    path: "/model",
    component: BuildModelPage,
    useLayout: false,
    useMenu: false,
    name: "Model",
  },
  {
    path: "*",
    component: NotFound,
    useLayout: false,
    useMenu: false,
    name: "404",
  },

];

export default routers;
