import React, {useEffect, useState, useMemo} from "react";
import classNames from "classnames";
import css from "./style.module.scss";
import Dropdown from "../Dropdown";
import useForgeViewer from "context/ForgeViewerContext";
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar'

export const Table = ({ data, changeStatus }) => {
  const [select, setSelect] = useState([]);
  const [length, setLength] = useState(10);
  const {viewer} = useForgeViewer()

  const onClickHandler = (e, el) => {
    const index = select.findIndex((item) => el.dbIds.includes(item))
    let selectedElements = []
    if(index !== -1) {
      if(e.ctrlKey || e.metaKey) {
        selectedElements = [...select]
        selectedElements.splice(index, 1)
      }
      setSelect(selectedElements)
    } else {
      if(e.ctrlKey || e.metaKey) {
        selectedElements = [...select, ...el.dbIds]
      } else {
        selectedElements = [...el.dbIds]
      }
      setSelect(selectedElements)
    }

    viewer.select(selectedElements)
  }

  useEffect(() => {
    return () => {
      if(viewer && viewer.impl) {
        viewer.isolate([])
        viewer.select([])
      }
    }
  }, [viewer])


  const showMoreHandler = () => {
      setLength((value) =>
          value + 10 > data.length ? data.length : value + 10
      )
  }

  return (
    <div className={css.section}>
      <PerfectScrollbar
          onYReachEnd={() => showMoreHandler()}>
        <div className={css.tablesWrapper}>
          <div className={css.mainTableWrapper}>
            <table className={css.table}>
              <thead>
              <tr>
                <th>Name</th>
                <th>Mark</th>
                <th>Level</th>
                <th>Area</th>
                <th>Length</th>
                <th>Mass</th>
              </tr>
              </thead>

              <tbody>
              {data.slice(0, length).map((el, i) => (
                  <tr
                      key={i}
                      onClick={(e) => onClickHandler(e, el)}
                      className={classNames({ [css.active]: select.findIndex((item) => el.dbIds.includes(item)) !== -1})}
                  >
                    <td><div className={css.item}>{el.name}</div></td>
                    <td><div className={css.item}>{el.mark}</div></td>
                    <td><div className={css.item}>{el.level}</div></td>
                    <td><div className={css.item}>{Number(el.area).toFixed(0)} <span>m<sup><small>2</small></sup></span></div></td>
                    <td><div className={css.item}>{Number(el.length).toFixed(0)}</div></td>
                    <td><div className={css.item}>{Number(el.mass).toFixed(2)} kg</div></td>
                  </tr>
              ))}
              </tbody>
            </table>
          </div>
          <table className={classNames(css.table, css.StatusTable)}>
            <thead>
            <tr>
              <th>Status</th>
            </tr>
            </thead>
            <tbody>
            {data.slice(0, length).map((el, i) => (
                <tr key={i}>
                  <td>
                    <Dropdown changeStatus={changeStatus} el={el} />
                  </td>
                </tr>
            ))}
            </tbody>
          </table>
        </div>
      </PerfectScrollbar>
    </div>
  );
};
