import React from "react";
import css from "./style.module.scss";
import classNames from "classnames";
const Card = ({ children, className, title }) => {
  return (
    <div className={classNames(css.card, className && className)}>
      {title && <div className={css.title}>{title}</div>}
      {children}
    </div>
  );
};

export default Card;
