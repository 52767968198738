import React, { useState } from "react";
import classNames from "classnames"
import { Popover } from "antd";
import Items from "./Items";
import css from './style.module.scss'

const Dropdown = ({changeStatus, el}) => {
  const [visible, setVisible] = useState(false);
  // const [select, setSelect] = useState(el?.status || 'Empty')
  const onSelectHandler = (item) => {
    // setSelect(item)
    setVisible(false)
    if(changeStatus) {
      changeStatus(el, item)
    }
  }
  return (
      <Popover
          placement="bottomLeft"
          visible={visible}
          content={<Items onSelect={onSelectHandler} active={el?.status} />}
          trigger="click"
          onVisibleChange={setVisible}
      >
        <div className={classNames(css.selected, { [css.active]: visible})}>
          <span>{el?.status}</span>
          <img src='/assets/icons/arrows/ArrowRightSmall.svg' alt='icon'/>
        </div>
      </Popover>
  )
}

export default Dropdown
