import React, { useState, useEffect, useRef, useCallback } from "react";
import { Row, Col } from "antd";
import classnames from "classnames";
import moment from "moment";
import DropDown from "./DropDown";
import Chart from "./Chart";
import css from "./style.module.scss";
const options = ["Day", "Week", "Month", "Select"];

const getDataDay = (sensor) => {
  const data = sensor.results
      .filter((result) => result.date.isSame(moment(), "day"))
  return data.reduce((acc, item) => {
    return {
      data: [...acc.data, item.value],
      labels: [...acc.labels, item.date.format('HH:mm')]
    }
  }, {data: [], labels: []})
};

const getDataWeek = (sensor) => {
  const data = sensor.results
      .filter((result) => result.date.isSame(moment(), "week"))
  return data.reduce((acc, item) => {
    const label = item.date.format('ddd')
    if(acc.labels.indexOf(label) >= 0) {
      return acc
    }
    const groupOneDay = data.filter((i) => i.date.isSame(item.date, "day"))
    const avg = groupOneDay.reduce((acc, item) => acc + item.value, 0) / groupOneDay.length
    return {
      data: [...acc.data, Math.floor(avg)],
      labels: [...acc.labels, label]
    }
  }, {data: [], labels: []})
};

const getDataMonth = (sensor) => {
  let monthData = sensor.results
      .filter((result) => result.date.isSame(moment(), "month"))

  return monthData.reduce((acc, item) => {
    const label = item.date.format('DD.MM.YY')
    if(acc.labels.indexOf(label) >= 0) {
      return acc
    }
    const groupOneDay = monthData.filter((i) => i.date.isSame(item.date, "day"))
    const avg = groupOneDay.reduce((acc, item) => acc + item.value, 0) / groupOneDay.length

    return {
      data: [...acc.data, Math.floor(avg)],
      labels: [...acc.labels, label]
    }
  }, {data: [], labels: []})
};

const getCustomData = (sensor, range) => {
  let monthData = sensor.results
      .filter((result) => result.date.isBetween(range.from, range.to))

  return monthData.reduce((acc, item) => {
    const label = item.date.format('DD.MM.YY')
    if(acc.labels.indexOf(label) >= 0) {
      return acc
    }
    const groupOneDay = monthData.filter((i) => i.date.isSame(item.date, "day"))
    const avg = groupOneDay.reduce((acc, item) => acc + item.value, 0) / groupOneDay.length

    return {
      data: [...acc.data, Math.floor(avg)],
      labels: [...acc.labels, label]
    }
  }, {data: [], labels: []})
};

const Sensor = ({ sensors, normalValue, valueType, tempError }) => {
  const [currentOption, setCurrentOption] = useState("Day");
  const [dateRange, setDateRange] = useState();
  const [chart, setChart] = useState(null)

  const [activeSensor, setActiveSensor] = useState(sensors[0] ? 0 : null)

  const canvasRef = useRef(null)

  // console.log(sensors?.[0] || null)

  const createChart = useCallback((context) => {
    const sensor = sensors[activeSensor]
    let opt
    switch (currentOption) {
      case "Day":
        opt = getDataDay(sensor)
        break
      case "Week":
        opt = getDataWeek(sensor)
        break
      case "Month":
        opt = getDataMonth(sensor)
        break
      case "Select":
        opt = getCustomData(sensor, dateRange)
        break
    }


    if(opt) {
      if(chart) chart.destroy()
      setChart(Chart({ ...opt, color: sensor.color }, context))
    }

  }, [sensors, activeSensor, currentOption])


  useEffect(() => {
    const context = canvasRef?.current?.getContext("2d")
    if(activeSensor !== null && context) {
      createChart(context)
    }
  }, [ canvasRef, activeSensor, createChart])


  return (
    <div className={css.sensorContent}>
      <Row>
        <Col>
          <div className={css.list}>
            {sensors.map((sensor, i) => {
              let lastValue = sensor.results[sensor.results.length - 1].value;
              return (
                <div
                  key={i}
                  className={classnames(css.item, {
                    [css.active]: activeSensor === i,
                  })}
                  onClick={() => setActiveSensor(activeSensor === i ? null : i)}
                >
                  <Row align="middle">
                    <Col span={2}>
                      <div
                        className={classnames(
                          css.status,
                          {
                            [css.error]: !sensor.hasError,
                          },
                          {
                            [css.ok]: sensor.hasError,
                          }
                        )}
                      />
                    </Col>
                    <Col span={14} offset={1}>
                      Sensor {sensor.name}
                    </Col>
                    <Col span={3}>{lastValue}</Col>
                    <Col span={2}>{valueType}</Col>
                  </Row>
                  {/* <div className={classnames("divider", css.marginDivider)} /> */}
                </div>
              );
            })}
          </div>
        </Col>

        <Col>
          <div className={css.chart}>
            <div className={css.info}>
              {tempError && (
                <div className={css.error}>
                  <div className={css.icon}/>
                  <div className={css.errorText}>{tempError}</div>
                </div>
              )}

              <div className={css.dropDown}>
                <DropDown
                  setDateRange={setDateRange}
                  setCurrentOption={setCurrentOption}
                  currentOption={currentOption}
                  options={options}
                />
              </div>
            </div>
            <div className={css['cart-wrapper']}>
              <canvas  ref={canvasRef} width="340" style={{display: activeSensor === null ? 'none' : 'block' }}/>
              { activeSensor === null && <div>Select a sensor to watch it’s info</div>  }
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Sensor;
