import React from "react";
import { connect } from "react-redux";
import {
  sort,
  search,
  getDocumentRequest,
  setDocumentRequest,
} from "../redux/actions/documentsActions";
import Documents from "../components/Documents";

const DocumentPage = ({
  documents,
  sort,
  search,
  setDocumentRequest,
  getDocumentRequest,
}) => {
  return (
    <Documents
      data={documents}
      sort={sort}
      search={search}
      setDocumentRequest={setDocumentRequest}
      getDocumentRequest={getDocumentRequest}
    />
  );
};
const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    sort: (sortType) => dispatch(sort(sortType)),
    search: (fileName) => dispatch(search(fileName)),
    getDocumentRequest: (documentType, buildId) =>
      dispatch(getDocumentRequest(documentType, buildId)),
    setDocumentRequest: (file, documentType, buildId) =>
      dispatch(setDocumentRequest(file, documentType, buildId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentPage);
