import { put, takeLatest, call } from "redux-saga/effects";
import sortBy from 'lodash/sortBy'
import {
  getBuildsSuccess,
  getBuildsError,
  // setNotificationReadSuccess,
  setPrecastState,
  setNotificationReadError,
} from "../actions/buildsActions";
import api from "../api/buildApi";

function* getBuilds() {
  try {
    const list = yield call(api.getBuilds, {});
    // console.log(list);

    if (!list) {
      yield put(getBuildsError({ error: "Server connection error" }));
      return;
    }

    if (list.length === 0) {
      yield put(getBuildsError({ error: "Data of builds is empty" }));
    } else {
      yield put(getBuildsSuccess(sortBy(list.slice(2), 'sort')));
    }
  } catch (error) {
    yield put(getBuildsError(error));
  }
}

function* setPrecast({ data }) {
  try {
    yield put(setPrecastState(data));
    yield call(api.setPrecast, data);
  } catch (error) {
   console.log(error)
  }
}

function* setNotification(action) {
  try {
    yield call(api.setNotification, action);
  } catch (error) {
    yield put(setNotificationReadError(error));
  }
}
function* setAllMark(action) {
  try {
    yield call(api.markAllRead, action);
  } catch (error) {
    yield put(setNotificationReadError(error));
  }
}
function* buildSaga() {
  yield takeLatest("GET_BUILDS_REQUEST", getBuilds);
  yield takeLatest("SET_NOTIFICATION_REQUEST", setNotification);
  yield takeLatest("SET_ALL_MARK_REQUEST", setAllMark);
  yield takeLatest("SET_PRECAST", setPrecast);
}

export default buildSaga;
