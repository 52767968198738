import React from "react";
import classNames from "classnames"
import css from './style.module.scss'

const items = ['Empty', 'Designed', 'Manufactured', 'Delivered', 'Assembled']

const Items = ({active, onSelect}) => {
  return (
      <div className={css.content}>
        {items.map(((item, i )=> (
            <div
              key={i}
              className={classNames(css.item, {[css.active]: active === item} )}
              onClick={() => onSelect(item)}
            >
              {item}
            </div>
        )))}
      </div>
  )
}

export default Items
