import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { Popover, Spin } from "antd";
import { connect } from "react-redux";
import {
  setBuild,
  setNotificationReadRequest,
  setAllMarkRequest,
} from "../../../redux/actions/buildsActions";
import DropdownBuilds from "./DropdownBuilds";
import Notifications from "./Notification";
import css from "./style.module.scss";
import { BASE_API_URL } from "../../../configs/base";

const getNotificationIcon = (user, currentBuild, notificationVisible) => {
  let isReadAll = true;
  let notificationIcon = "/assets/icons/appbar/NotificationCircle.svg";
  const notifications = currentBuild.notifications;

  notifications.forEach((el, i) => {
    let { isRead } = el;
    let isReader = isRead.find((el) => el._id === user.id);
    if (!isReader) {
      isReadAll = false;
    }
  });
  notificationIcon = `/assets/icons/appbar/Notification${
    !isReadAll ? "Circle" : ""
  }${notificationVisible ? "Active" : ""}.svg`;

  return notificationIcon;
};

const Appbar = ({
  builds,
  setBuild,
  useMenu,
  setNotificationReadRequest,
  user,
  setAllMarkRequest,
}) => {

  const [dropDownVisible, setDropDownVisible] = useState(false);
  const [notificationVisible, setNotificationVisible] = useState(false);
  const [notificationIcon, setNotificationIcon] = useState(
    "/assets/icons/appbar/Notification.svg"
  );

  const currentBuild = builds.builds[builds.currentBuildId];


  const onChangeBuildHandler = (i) => {
    setBuild(i)
    setDropDownVisible(false)
  }
  useEffect(() => {
    if (builds && currentBuild) {
      let icon = getNotificationIcon(user, currentBuild, notificationVisible);
      setNotificationIcon(icon);
    }
  }, [builds, notificationVisible, user, currentBuild]);

  return (
    <nav className={classNames(css.appbar)} id="navbar">
      {!useMenu && (
          <Link to="/">
            <div className={classNames("button black", css.close)}>
              Close
            </div>
          </Link>
      )}
      {!builds.isLoading && !builds.error &&  builds && useMenu && (
        <Popover
          placement="bottomLeft"
          visible={dropDownVisible}
          content={<DropdownBuilds data={builds} setBuild={(i) => onChangeBuildHandler(i)} />}
          trigger="click"
          onVisibleChange={(isVisible) => {
            setDropDownVisible(isVisible);
          }}
        >
          <div className={css.builds}>
            {!builds.error && (
              <div className={classNames(css.text)}>{currentBuild.name}</div>
            )}
            <img
              className={classNames(
                css.arrow,
                { [css.up]: dropDownVisible },
                { [css.down]: !dropDownVisible }
              )}
              src="/assets/icons/arrows/ArrowRightSmall.svg"
              alt="icon"
            />
          </div>
        </Popover>
      )}

      {builds.error && <div>{builds.error}</div>}

      {builds.isLoading && (
        <div>
          <Spin />
        </div>
      )}

      {!builds.isLoading && !builds.error && (
        <div className={css.buttons}>
          <div className={classNames(css.iconSvg)}>
            <img src="/assets/icons/appbar/Education.svg" alt="icon" />
          </div>
          {builds.builds && (
            <Popover
              placement="bottomRight"
              content={
                <Notifications
                  notifications={currentBuild.notifications}
                  buildId={builds.currentBuildId}
                  setNotificationReadRequest={setNotificationReadRequest}
                  user={user}
                  setAllMarkRequest={setAllMarkRequest}
                />
              }
              trigger="click"
              onVisibleChange={(visible) => {
                setNotificationVisible(visible);
              }}
            >
              <div
                className={classNames(css.iconSvg, {
                  [css.notification]: true,
                })}
              >
                <img src={notificationIcon} alt="icon" />
              </div>
            </Popover>
          )}

          <div className={css.user}>
            <div className={css.icon}>
              <img src={BASE_API_URL + user.photo} alt="icon" />
            </div>

            <div className={css.name}>
              {user.name} {user.sName}
            </div>
          </div>
        </div>
      )}
    </nav>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
    builds: state.builds,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setNotificationReadRequest: (index, buildId, user) =>
      dispatch(setNotificationReadRequest(index, buildId, user)),
    setBuild: (index) => dispatch(setBuild(index)),
    setAllMarkRequest: (buildId, user) =>
      dispatch(setAllMarkRequest(buildId, user)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Appbar);
