import React from "react";
import classNames from "classnames";
import css from "./style.module.scss";
import { BASE_API_URL } from "../../../../configs/base";

const DropdownBuilds = ({ data, setBuild }) => {

  return (
    <div className={css.content}>
      <div className={css.header}>Objects list</div>
      <div className={css.list}>
        {data.builds.map((el, i) => (
          <div
            className={classNames(
              css.item,
              {
                [css.active]: i === data.currentBuildId,
              },
              {
                [css.notActive]: i !== data.currentBuildId,
              }
            )}
            key={i}
            onClick={() => {
              if (i === data.builds.length - 1) {
                window.location = process.env.REACT_APP_FRAKKI_URL
              } else {
                setBuild(i);
              }
            }}
          >
            {!data.isLoading && (
              <img
                src={`${BASE_API_URL}/public${el.iconSmall}`}
                className={css.icon}
                alt="build"
              />
            )}

            <div className={css.description}>
              <div className={css.top}>{el.name}</div>
              <div className={css.bottom}>{el.description}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DropdownBuilds;
